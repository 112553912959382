import React from 'react'

import { Link } from 'react-router-dom';
import { serverUrl } from '../../config';
import classNames from 'classnames';

const ModelsMenu = ({ models, modelSlug, setModel, makeUrl, images, sendRaw }) => {

  return (
    <div className="gpage__models">
      {models && Object.values(models).filter(m => m.status).sort((a,b) => a.position > b.position ? 1: -1 ).map((one, i) => {
        const cls = classNames({ active: one.slug === modelSlug });
        return (
          <React.Fragment key={`model-${one.id}`}>
            <Link to={makeUrl({ modelSlug: one.slug })} onClick={() => { sendRaw('AccessoriesPage', 'choose_model', one.name); setModel(one.slug); }} className={cls}>
              { images[one.image] &&
                <img alt="" src={serverUrl + images[one.image].imageFileName} />
              }
              <span>
                {one.name}
              </span>
            </Link>
            { i === 3 && <br /> }
          </React.Fragment>
        );
      })}
    </div>
  )

}

export default ModelsMenu