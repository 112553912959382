/*
 *
 * Conf
 *
 */

import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { Collapse } from 'react-collapse'
import classnames from 'classnames'
import { frontDomain } from 'config'


import { getDealers } from 'models/dealers/actions'
import { makeSelectMainDomain, makeSelectDomainDealersFull } from 'containers/App/selectors'
import { makeSelectEntities, makeSelectSortedValues } from 'models/selectors'

import Row from 'components/Row'
import Col from 'components/Col'
import Container from 'components/Container'
import Button from 'components/Button'

import Arrow from 'images/arrow.svg'
import LogoMir from '!!file-loader?modules!images/logo-mir.png'
import LogoVisa from '!!file-loader?modules!images/logo-visa.png'
import LogoMc from '!!file-loader?modules!images/logo-mastercard.png'
import LogoJcb from '!!file-loader?modules!images/logo-jcb.png'
import LogoAlfa from '!!file-loader?modules!images/alfabank.png'
import LogoVisaVerified from '!!file-loader?modules!images/logo_visa_verified.png'
import LogoMcVerified from '!!file-loader?modules!images/logo_mc_verified.png'
import LogoMirVerified from '!!file-loader?modules!images/logo_mir_verified.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'images/card.svg'
import Svg from 'components/Svg'
import Truck from 'images/truck.svg'



import ReactTooltip from 'react-tooltip';

import Checkbox from 'components/Checkbox'


const Filter = styled.div`
  padding: 6px 10px;
  `

const DealerInfo = ({ dealer, paymentable, deliverable, cityDeliverable, force = false }) => {
  return (
    <>
      <div>
        Адрес: {dealer.address}
        <br />
        Телефон: {dealer.phone}, Эл. почта: {dealer.email}
        <br />
        <br />
        {dealer.legalInfo &&
          <>
          <h4>Реквизиты:</h4> {dealer.legalInfo && dealer.legalInfo.split("\n").map((i, key) => {
              return <div key={key} dangerouslySetInnerHTML={{ __html: i }} />;
            })}
          </>
        }
      </div>

      <h3 style={{ marginTop: 15 }}>Оплата</h3>
      <h4>Наличный расчёт</h4>
      <p>Оплата наличными при получении товара в дилерском центре.</p>
      <h4>Банковской картой</h4>
      <p>Оплата банковской картой при получении товара в дилерском центре.</p>
      {((force && !paymentable) || (force && paymentable.bank !== 'BANK_KIND_ALFA') || (paymentable && paymentable.bank === 'BANK_KIND_SBER')) &&
          <>
            <h4>Банковской картой онлайн</h4>
            <p style={{marginBottom: 20}}>
              Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку Оплата заказа онлайн. Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платёжных систем:
            </p>
            <div>
              <ul className='paydealer__cardlist'>
                <li>МИР <img src={LogoMir}/> </li>
                <li>VISA International <img src={LogoVisa}/> </li>
                <li>Mastercard Worldwide <img src={LogoMc}/></li>
                <li>JCB <img src={LogoJcb}/></li>
              </ul>
            </div>
            <div style={{fontSize: 12, lineHeight: '14px'}}>
              <p>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure для проведения платежа также может потребоваться ввод специального пароля.</p>
              <p>Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.</p>
            </div>
          </>
        }
        {paymentable && paymentable.bank === 'BANK_KIND_ALFA' &&
        <>
          <h4>Банковской картой онлайн</h4>
          <p style={{ marginBottom: 20 }}>
            <img style={{ width: 100, marginBottom: 10 }} src={LogoAlfa} /><br />
            Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку Оплата заказа онлайн. Оплата происходит через АО «АЛЬФА-БАНК» с использованием банковских карт следующих платёжных систем:
          </p>
          <div>
            <ul className='paydealer__cardlist'>
              <li>МИР <img src={LogoMir} /> </li>
              <li>VISA International <img src={LogoVisa} /> </li>
              <li>Mastercard Worldwide <img src={LogoMc} /></li>
            </ul>
          </div>
          <div style={{ fontSize: 12, lineHeight: '14px' }}>
            <p>Услуга оплаты через интернет осуществляется в соответствии с Правилами международных платежных систем Visa, MasterCard и Платежной системы МИР на принципах соблюдения конфиденциальности и безопасности совершения платежа, для чего используются самые современные методы проверки, шифрования и передачи данных по закрытым каналам связи. Ввод данных банковской карты осуществляется на защищенной платежной странице АО «АЛЬФА-БАНК».</p>
            <p>На странице для ввода данных банковской карты потребуется ввести данные банковской карты: номер карты, имя владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA, CVC2 для MasterCard, Код Дополнительной Идентификации для МИР). Все необходимые данные пропечатаны на самой карте. Трёхзначный код безопасности — это три цифры, находящиеся на обратной стороне карты.</p>
            <p>Далее вы будете перенаправлены на страницу Вашего банка для ввода кода безопасности, который придет к Вам в СМС. Если код безопасности к Вам не пришел, то следует обратиться в банк выдавший Вам карту.</p>
          </div>
          <div>
            <img src={LogoVisaVerified} style={{ width: 150, margin: '0 10px' }} />
            <img src={LogoMcVerified} style={{ width: 150, margin: '0 10px' }} />
            <img src={LogoMirVerified} style={{ width: 150, margin: '0 10px' }} />
          </div>
          <div style={{ fontSize: 12, lineHeight: '14px' }}>
            <p>Случаи отказа в совершении платежа:</p>
            <ul>
              <li>банковская карта не предназначена для совершения платежей через интернет, о чем можно узнать, обратившись в Ваш Банк;</li>
              <li>недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на банковской карте Вы можете узнать, обратившись в банк, выпустивший банковскую карту;</li>
              <li>данные банковской карты введены неверно;</li>
              <li>истек срок действия банковской карты. Срок действия карты, как правило, указан на лицевой стороне карты (это месяц и год, до которого действительна карта). Подробнее о сроке действия карты Вы можете узнать, обратившись в банк, выпустивший банковскую карту;</li>
            </ul>
            <p>По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой сайта, Вы можете обращаться по следующему телефону: {dealer.phone}.</p>
            <p>Предоставляемая вами персональная информация (имя, адрес, телефон, e-mail, номер банковской карты) является конфиденциальной и не подлежит разглашению. Данные вашей кредитной карты передаются только в зашифрованном виде и не сохраняются на нашем Web-сервере.</p>
          </div>
        </>
      }
      {paymentable && paymentable.bank === 'BANK_KIND_MKB' &&
        <>
          <h4>Банковской картой онлайн</h4>
          <div>
            <p>К оплате принимаются платежные карты: VISA Inc, MasterCard WorldWide и Мир</p>
            <p>Для оплаты товара банковской картой при оформлении заказа в интернет-магазине выберите Способ оплаты: банковской картой.</p>
            <p>При оплате заказа банковской картой, обработка платежа происходит на авторизационной странице ПАО «МОСКОВСКИЙ КРЕДИТНЫЙ БАНК», где Вам необходимо ввести данные Вашей банковской карты:</p>
            <ul>
              <li>тип карты</li>
              <li>номер карты,</li>
              <li>срок действия карты (указан на лицевой стороне карты)</li>
              <li>Имя держателя карты (латинскими буквами, точно также как указано на карте)</li>
              <li>CVC2/CVV2 код</li>
            </ul>
            <p>Далее нажать на кнопку «ОПЛАТИТЬ».</p>
          </div>
          <div style={{ fontSize: 12, lineHeight: '14px' }}>
            <p>Для дополнительной аутентификации держателя карты используется протокол 3D Secure. Если Ваш Банк поддерживает данную технологию, Вы будете перенаправлены на сервер Вашего Банка для дополнительной идентификации. Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.</p>
            <p>Безопасность обработки интернет-платежей через ПАО «МОСКОВСКИЙ КРЕДИТНЫЙ БАНК» гарантирована международным сертификатом безопасности PCI DSS.</p>
            <p>Передача информации происходит с применением технологии шифрования SSL.</p>
            <p>Советы и рекомендации по необходимым мерам безопасности проведения платежей с использованием банковской карты:</p>
            <ul>
              <li>берегите свои пластиковые карты так же, как бережете наличные деньги. Не забывайте их в машине, ресторане, магазине и т.д.</li>
              <li>никогда не передавайте номер своей кредитной карты по телефону каким-либо лицам или компаниям</li>
              <li>всегда имейте под рукой номер телефона для экстренной связи с банком, выпустившим вашу карту, и в случае ее утраты немедленно свяжитесь с банком</li>
              <li>вводите реквизиты карты только при совершении покупки. Никогда не указывайте их по каким-то другим причинам.</li>
              <li>проверьте, установлено ли защищенное SSL-соединение: адрес в адресной строке должен начинаться с https:// и в правом нижнем углу браузера должно появиться изображение замка.</li>
            </ul>
          </div>
        </>
      }
      <h3 style={{ marginTop: 15 }}>Доставка</h3>
      {(!deliverable && !cityDeliverable) && <p>Дилер не осуществляет доставку.</p>}
      {deliverable &&
        <>
          <h4>Доставка агрегатором</h4>
          <div>
            <p>Доставка осуществляется транспортными компаниями и курьерскими службами, список которых предоставляется агрегатором служб доставки.</p>
          </div>
        </>
      }
      {cityDeliverable &&
        <>
          <h4>Бесплатная доставка по городу</h4>
          <div>
          <p>Осуществляется бесплатная доставка по городу при сумме заказа от {dealer.freeCityDeliveryThreshold} руб</p>
          <p>
            {dealer.cityDeliveryInfo && dealer.cityDeliveryInfo.split("\n").map((i, key) => {
              return <div key={key} dangerouslySetInnerHTML={{ __html: i }} />;
            })}
          </p>
          </div>
        </>
      }      
      <h4>Самовывоз</h4>
      <p>Самовывоз по адресу: {dealer.address}</p>
    </>
  )
}


export class PaymentMethods extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = { search: '', collapsed: {}, withDelivery: false, withPayment: false }
    this.toggle = this.toggle.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
  }

  componentDidMount() {
    const { isMainDomain } = this.props
    if (isMainDomain) {
      this.props.getDealers()
    }
  }

  toggle(id) {
    this.setState((prevState, props) => {
      return { collapsed: { ...prevState.collapsed, [id]: !prevState.collapsed[id] } };
    });
  }

  handleSearchChange(e) {
    const query = e.target.value
    this.setState({ search: query })
  }

  render() {
    const { isMainDomain, domainDealers, sortedDealers, payables, deliverables } = this.props
    let dealersToShow = isMainDomain ? sortedDealers.filter(d => d.name.toLowerCase().includes(this.state.search.toLowerCase())) : domainDealers
    if (this.state.withDelivery) {
      dealersToShow = dealersToShow.filter(dealer => (deliverables && deliverables[dealer.kod]) || (dealer.freeCityDelivery))
    }
    if (this.state.withPayment) {
      dealersToShow = dealersToShow.filter(dealer => payables && payables[dealer.kod])
    }    
    const forceShowPayment = ['192.168.56.102', 'partsskoda.yuti.ru'].includes(frontDomain)
    // console.log({ deliverables})
    return (
      <div>
        <Helmet
          title="Оплата и доставка"
          meta={[
            { name: 'description', content: 'Оплата и доставка' },
          ]}
        />
        <div className="content">
          <div style={{ padding: '25px 0 50px' }}>
            <h1 style={{ margin: '0 auto 40px', padding: '0 30px', maxWidth: 830, fontSize: 30, textTransform: 'uppercase', fontWeight: 'bold' }}>Оплата и доставка</h1>
            <div style={{ fontSize: 16, padding: '0 30px', lineHeight: '24px', maxWidth: 830, margin: '0 auto', textAlign: 'justify' }}>
              {dealersToShow.length > 1 && <p>Условия оплаты и доставки отличаются в зависимости от дилерского центра.</p>}

              {isMainDomain &&
                <>
                  <Filter>
                    <Checkbox
                      onClick={() => this.setState({ withDelivery: !this.state.withDelivery })}
                      checked={this.state.withDelivery}>
                      Дилеры с доставкой
                    </Checkbox>
                  </Filter>
                  <Filter>
                    <Checkbox
                        onClick={() => this.setState({ withPayment: !this.state.withPayment })}
                      checked={this.state.withPayment}>
                      Дилеры с оплатой онлайн
                    </Checkbox>
                  </Filter>
                </>
              }

              {isMainDomain &&
                <div style={{ margin: '20px 0' }}>
                  <div className="input-group">
                    <input style={{ padding: '0 15px' }} placeholder='Введите название дилерского центра' type='text' value={this.state.search} onChange={this.handleSearchChange} />
                  </div>
                </div>
              }

              {dealersToShow.length === 1 &&
                <>
                  {(() => {
                    let paymentable = payables && payables[Number(dealersToShow[0].kod)]
                    if (['754', '791', '1748'].includes(dealersToShow[0].kod)) {
                      paymentable = {
                        bank: 'BANK_KIND_ALFA',
                        code: dealersToShow[0].kod
                      }
                    }
                    return (
                      <>
                        <div style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 20 }}>{dealersToShow[0].name}</div>
                        <DealerInfo force={forceShowPayment} dealer={dealersToShow[0]} paymentable={payables && payables[Number(dealersToShow[0].kod)] || forceShowPayment} deliverable={deliverables && deliverables[Number(dealersToShow[0].kod)]} cityDeliverable={dealersToShow[0].freeCityDelivery} />
                      </>
                    )
                  })()}
                </>
              }

              {dealersToShow.length > 1 && dealersToShow.map(dealer => {
                const open = this.state.collapsed[dealer.id] || false
                let paymentable = payables && payables[Number(dealer.kod)]
                if (['754', '791', '1748'].includes(dealersToShow[0].kod)) {
                  paymentable = {
                    bank: 'BANK_KIND_ALFA',
                    code: dealersToShow[0].kod
                  }
                }
                const deliverable = deliverables && deliverables[Number(dealer.kod)]
                return (
                  <div className={classnames('paydealer', { open })} key={dealer.id}>
                    <div style={{ display: 'flex' }} className='paydealer__title' onClick={() => this.toggle(dealer.id)}>{dealer.name}
                      {(forceShowPayment || paymentable) &&
                        <>
                          <Svg icon={Card} small style={{ marginLeft: 5 }} data-tip={'Доступна оплата онлайн'} />
                        </>
                      }
                      {(deliverable || dealer.freeCityDelivery) && 
                        <>
                          <Svg icon={Truck} small style={{ marginLeft: 5 }} data-tip={'Доступна доставка'} />
                          <ReactTooltip  />   
                        </>
                      }                   
                      <div className='moararrow' dangerouslySetInnerHTML={{ __html: Arrow }} style={{ transform: `rotate(${open ? 180 : 90}deg)` }} />
                    </div>
                    <Collapse isOpened={open}>
                      <div className="paydealer__desc">
                        <DealerInfo force={forceShowPayment} dealer={dealer} paymentable={paymentable || forceShowPayment} deliverable={deliverable} cityDeliverable={dealer.freeCityDelivery} />
                      </div>
                    </Collapse>
                  </div>
                )
              })}
{/*              <div style={{fontSize: 12, lineHeight: '14px', background: "#fff", padding: '10px 15px', marginTop: 10}}>
                <p>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure для проведения платежа также может потребоваться ввод специального пароля.</p>
                <p>Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.</p>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}



PaymentMethods.propTypes = {
};

const mapStateToProps = createStructuredSelector({
  sortedDealers: makeSelectSortedValues('dealers', 'name'),
  dealers: makeSelectEntities('dealers'),
  payables: makeSelectEntities('payables'),
  deliverables: makeSelectEntities('deliverables'),
  isMainDomain: makeSelectMainDomain(),
  domainDealers: makeSelectDomainDealersFull(),
});

function mapDispatchToProps(dispatch) {
  return {
    getDealers: () => dispatch(getDealers()),
    // setMapDealer: (id) => dispatch(setMapDealer(id)),
    setPopup: (id) => dispatch(setPopup(id)),
  };
}





export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
