/*
 *
 * App reducer
 *
 */

import { fromJS } from 'immutable';
import {
  LOGIN,
  SET_PAGE_DATA,
  SET_CURRENT_USER,
  SET_CURRENT_CART_ID,
  SET_CURRENT_REGION_ID,
  SET_CURRENT_DOMAIN_ID,
  SET_POPUP,
  SET_MENU,
  SET_DEALER_ORDER_COUNT,
  FAV_TOGGLE,
  SET_FAV,
  SET_LOADING,
  SET_AUTH_ERROR,
  SET_MESSAGES_SHOW,
  SET_DOMAIN_LOADING,
  COMPLETE_DOMAIN_CHECK,
  COMPLETE_AUTH_CHECK,
  SET_LOCATION,
} from './constants';
import { isServer } from 'helpers'

const initialState = fromJS({
  pages: {},
  currentUserId: null,
  cartId: null,
  regionId: null,
  popup: [],
  popupInfo: [],
  menu: null,
  domain: isServer ? null : (new URL(window.location.href)).hostname,
  host: null,
  currentDomainId: null,
  dealerOrderCount: 0,
  browserIsOk: true,
  favs: [],
  compares: [],
  loading: true,
  authError: false,
  authChecking: true,
  messagesShow: true,
  domainLoading: true,
  location: null
});

function AppReducer (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return state;
    case SET_LOADING:
      return state
        .set('loading', action.payload)        
    case SET_DOMAIN_LOADING:
      return state
        .set('domainLoading', action.payload)
    case COMPLETE_DOMAIN_CHECK:
      return state
        .set('domainLoading', false)
    case COMPLETE_AUTH_CHECK:
      return state.set('authChecking', false);
    case SET_MESSAGES_SHOW:
      return state.set('messagesShow', action.show);
    case SET_PAGE_DATA:
      return state.setIn(['pages', action.payload.page, action.payload.name], action.payload.value);
    case SET_CURRENT_USER:
      return state.set('currentUserId', action.user);
    case SET_CURRENT_CART_ID:
      return state.set('cartId', action.cartId);
    case SET_CURRENT_REGION_ID:
      if (!state.get('currentUserId')) {
        return state.set('regionId', action.regionId);
      }
      return state;
    case "SERVER_SET_DOMAIN":
      return state.set('domain', action.domain);
    case "SERVER_SET_HOST":
      return state.set('host', action.host);      
    case "SERVER_SET_BROWSER_IS_OK":
      return state.set('browserIsOk', action.browserIsOk);      
    case SET_CURRENT_DOMAIN_ID:
      return state.set('currentDomainId', action.domainId);
    case SET_DEALER_ORDER_COUNT:
      return state.set('dealerOrderCount', action.count);
    case SET_MENU:
      return state.set('menu', state.get('menu') === action.id ? null : action.id);
    case SET_FAV:
      return state.set(action.tp === 1 ? 'compares' : 'favs', fromJS(action.favs));
    case FAV_TOGGLE:
      if (!state.get('currentUserId')) {
        const tp = action.payload.data.type === 1 ? 'compares' : 'favs';
        const cur = state.get(tp);
        const { payload: { data: { goodId } } } = action;
        if (cur.includes(goodId)) {
          return state.set(tp, cur.filter((item) => item !== goodId));
        }
        return state.set(tp, cur.push(goodId));
      }
      return state;
    case SET_POPUP: {
      const popup = state.get('popup');
      const popupInfo = state.get('popupInfo');

      if (action.popup === null) {
        return state
          .set('popup', state.get('popup').slice(0, -1))
          .set('popupInfo', state.get('popupInfo').slice(0, -1));
      }
      return state
        .set('popup', popup.set(popup.size, action.popup))
        .set('popupInfo', popupInfo.set(popup.size, action.options));
    }
    case SET_AUTH_ERROR:
      return state.set('authError', action.authError)
    case SET_LOCATION:
      return state.set('location', action.location)
    default:
      return state;
  }
}

export default AppReducer;
