/**
 *
 * GoodPage
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router';

import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { frontloadConnect } from 'react-frontload';

import { serverUrl } from '../../config';

import { GOOD_OFFERS, NOTIFY_STOCK } from 'components/Popup/constants';
import heroAcc from 'images/heroacc_small.jpg';

import { makeSelectEntities } from 'models/selectors';
import { makeSelectPageData, makeSelectCurrentDomain, makeSelectFavs } from 'containers/App/selectors';

import { setPageData, favToggle, setPopup } from 'containers/App/actions';
import { getGoodById } from 'models/goods/actions';

import NotFound from 'containers/NotFoundPage';
import Button from 'components/Button';
import Container from 'components/Container';
import { mergeDealerGood, formatPrice, createUrl } from 'helpers';

import PriceDisclaimer from 'components/PriceDisclaimer';
import 'css/components/gview.sass';
import 'css/components/table.sass';
import Lightbox from 'react-images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faStar } from '@fortawesome/free-solid-svg-icons';
import Good from 'components/Good';
import BreadCrumbs from 'components/BreadCrumbs';
import { pages } from 'settings'
import * as metricsActions from 'containers/App/metricsActions'

import FacetRev from 'components/FacetRev';
import { makeSelectHost } from 'containers/App/selectors';
import { useSelector } from 'react-redux'


const GoodLd = ({ good, images }) => {
  if (!good) return null
  const imgs = good.images.sort((a, b) => images[a].position - images[b].position);
  const host = useSelector(makeSelectHost())

  const ld = {
    "@context": "http://schema.org/",
    "@type": "Product",
    "name": good.name,
    "image": serverUrl + images[imgs[0]].imageBig,
    "description": good.description.replace(/<[^>]+>/g, ''),
    "brand": {
      "@type": "Brand",
      "name": "Skoda"
    },
    "offers": {
      "@type": "Offer",
      "priceCurrency": "RUB",
      "price": good.price,
      "url": `${host}/accessories/show/${good.productId}`,
      "availability": "https://schema.org/InStock",
      "itemCondition": "https://schema.org/NewCondition"
    }
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(ld)}</script>
    </Helmet>
  )
}

class GoodView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { image: 0, lightboxIsOpen: false }
    this.setImage = this.setImage.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);    
  }

  componentDidMount () {
    window.scrollTo(0, 0);
  }


  setImage (n) {
    this.setState({ image: n });
  }

  closeLightbox () {
    this.setState({ lightboxIsOpen: false });
  }

  gotoPrevious () {
    this.setState((state) => ({ image: state.image === 0 ? this.props.good.images.length - 1 : state.image - 1 }));
  }

  gotoNext () {
    this.setState((state) => ({ image: state.image === this.props.good.images.length - 1 ? 0 : state.image + 1 }));
  }

  render () {
    const {
      good, goods, images, categories, models, setPopup, dealerGoods, domain, favs, compares, favToggle, offers, sendRaw,
    } = this.props;
    const imgs = good.images.sort((a, b) => a.position - b.position);
    
    let stock = 0
    const singleDealerDomain = domain && domain.dealers && !domain.dealers.length
    if(singleDealerDomain) {
      const dealerId = domain && domain.dealerId
      let offerId = good.offers.filter(offerId => offers[offerId].dealerId === dealerId);
      if (offerId.length) {
        stock = offers[offerId].quantity
      }      
    }

    const goodDisplay = mergeDealerGood({ row: good, dealerGoods, dealerId: domain && domain.dealerId });
    // console.log(goodDisplay)
    const fav = favs.includes(goodDisplay.id);
    const breacrumbItems = [{
      title: 'Аксессуары',
      link: '/accessories',
      onClick: () => { sendRaw('Accessory_MainPage', 'click_accessories') }
    }];

    if (goodDisplay.categories && goodDisplay.categories[0] && categories[goodDisplay.categories[0]]) {
      breacrumbItems.push({
        title: categories[goodDisplay.categories[0]].name,
        link: createUrl(null, categories[goodDisplay.categories[0]].slug),
        onClick: () => sendRaw('Accessory_MainPage', 'click_accessories', categories[goodDisplay.categories[0]].name)
      });
    }

    breacrumbItems.push({
      title: goodDisplay.name,
      link: `/accessories/show/${(goodDisplay.productId || '').toUpperCase()}`,
      last: true,
    });

    const buyable = !(singleDealerDomain && !stock)
    return (
      <React.Fragment>
        <BreadCrumbs items={breacrumbItems} />

        <div className="gview">
          <div className="gview__images">
            <div className="gview__thumbs">
              { imgs.map((imgId, i) => {
                const cls = classNames('gview__thumb', { active: i === this.state.image });
                return (
                  <div key={`img-thumb-${imgId}`} onClick={() => { sendRaw('Accessory_MainPage', 'click_photo', i); this.setImage(i)}} className={cls}>
                    <img alt="" src={serverUrl + images[imgId].imageSmall} />
                  </div>
                );
              })}
            </div>
            <div className="gview__main-image">
              { images[imgs[this.state.image]] &&
                <img alt="" src={serverUrl + images[imgs[this.state.image]].imageBig} />
              }
              <div className="gview__zoom" onClick={() => this.setState({ lightboxIsOpen: true })}>
                <FontAwesomeIcon icon={faSearch} />
              </div>

            </div>
          </div>

          <Lightbox
            images={imgs.map((imgId, i) => ({ src: serverUrl + images[imgId].imageBig }))}
            isOpen={this.state.lightboxIsOpen}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            onClose={this.closeLightbox}
            currentImage={this.state.image}
            backdropClosesModal
            imageCountSeparator=" из "
            width={1600}
            closeButtonTitle="Закрыть (Esc)"
            rightArrowTitle="Следующее фото"
            leftArrowTitle="Предыдущее фото"
          />
          <div className="gview__info">
            <h1>
              {goodDisplay.name}
            </h1>
            <div className="gview__id ws-pre">
              {goodDisplay.originalId}
            </div>
            <div className="gview__price">
              {formatPrice(goodDisplay.price)}
            </div>
            {singleDealerDomain &&
              <div className='gview__stock'>
                В наличии: {stock} шт.
              </div>
            }

            { buyable && 
              <Button onClick={() => { 
                sendRaw('Accessory_MainPage', 'click_buy', goodDisplay.originalId)
                setPopup(GOOD_OFFERS, { id: goodDisplay.id }); 
              }}>
                Купить
              </Button>
            }
          
            { !buyable && 
              <Button small secondary={true} onClick={() => { setPopup(NOTIFY_STOCK, { productId: goodDisplay.productId }); }}>
                Узнать о поступлении
              </Button>
            }

            <div
              className={classNames('gview__fav', { active: fav })}
              onClick={() => new Promise((resolve, reject) => {
                sendRaw('Accessory_MainPage', !fav ? 'add_favourite' : 'delete_favourite', goodDisplay.originalId)
                favToggle({ data: { goodId: goodDisplay.id }, resolve, reject });
              })}
            >
              { fav &&
                (
                  <React.Fragment>
                    <FontAwesomeIcon icon={faStar} />
                    {' '}
                    Удалить из избранного
                  </React.Fragment>
                )
              }
              { !fav &&
                (
                  <React.Fragment>
                    <FontAwesomeIcon icon={faStar} />
                    {' '}
                    В избранное
                  </React.Fragment>
                )
              }
            </div>

          </div>
          <div className="gview__desc">
            <h3 className="gview__desc-title">
              Описание
            </h3>
            <div className="gview__desc-text" dangerouslySetInnerHTML={{ __html: goodDisplay.description }} />
          </div>
          <table className="tableinfo" style={{ marginBottom: 20 }}>
            <tbody>
              <tr>
                <td>
                  <b>
                    Модели
                  </b>
                </td>
                <td>
                  { goodDisplay.models && goodDisplay.models.filter(m => models[m].status).map((modelId) => (
                    <div key={`good-model-${modelId}`} className="gview__category">
                      <Link onClick={() => sendRaw('Accessory_MainPage', 'choose_model_bottom', models[modelId].name.toUpperCase())} to={createUrl(models[modelId].slug)} className="tableinfo__item">
                        <div className="tableinfo__img">
                          <img alt="" src={serverUrl + images[models[modelId].image].imageFileName} />
                        </div>
                        <span>
                          {models[modelId].name.toUpperCase()}
                        </span>
                      </Link>
                    </div>
                  ))}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    Категории
                  </b>
                </td>
                <td>
                  { goodDisplay.categories && goodDisplay.categories.map((categoryId) => (
                    <Link onClick={() => sendRaw('Accessory_MainPage', 'choose_category_bottom', categories[categoryId].name)} to={createUrl(null, categories[categoryId].slug)} key={`good-category-${categoryId}`} className="gview__category">
                      {categories[categoryId].name}
                    </Link>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{width: '100%', margin: '0 0 15px 0'}}>
            <Link onClick={() => sendRaw('Accessory_MainPage', 'guarantees', goodDisplay.originalId)} className="link" to={pages.guarantee}>Гарантийные условия</Link>
          </div>
          <PriceDisclaimer />
          { (goodDisplay.collaterals && goodDisplay.collaterals.length > 0) &&
            (
              <div className="gview__collaterals">
                <h3>
                  Сопутствующие товары
                </h3>
                <div className="goods">
                  {goodDisplay.collaterals && goodDisplay.collaterals.map((one) => (
                    <Good key={`collateral-${one}`} row={one} images={images} setPopup={setPopup} favToggle={favToggle} favs={favs} compares={compares} />
                  ))}
                </div>
              </div>
            )
          }
        </div>
      </React.Fragment>
    );
  }
}

function Loading ({ productId }) {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {productId}
        </title>
        <meta name="description" content="{productId}" />
      </Helmet>
      <div className="panel">
          Загрузка..
      </div>
    </React.Fragment>
  );
}

function Success (props) {
  const { good } = props;
  return (
    <React.Fragment>
      <Helmet>
        { good && 
          <>
            <title>
              {good.name} {good.productId} – купите у официальных дилеров Шкода
            </title>
          <meta name="description" content={`Оригинальные запчасти и аксессуары ŠKODA. ${good.name} ${good.productId}. Закажите аксессуары и запасные части для автомобилей у официальных дилеров.`} />
          </>
        }
      </Helmet>
      {good &&
        <GoodView {...props} />
      }
    </React.Fragment>
  );
}




const frontload = async props => {
  const { productId } = props.computedMatch.params;
  props.setPageData('status', 'loading');

  try {
    await new Promise((resolve, reject) => {
      props.getGoodById({ productId, resolve, reject });
    })
    if(productId !== productId.toUpperCase()) {
      props.staticContext.url = `/accessories/show/${productId.toUpperCase()}`
      return false;
    }
    props.setPageData('status', 'success');
  } catch(e) {
    if (props.staticContext) props.staticContext.not_found = true;
    props.setPageData('status', 'error');    
  }

}



export class GoodPage extends React.Component { // eslint-disable-line react/prefer-stateless-function

  // componentDidMount() {
    // console.log('mounted')
  // }

  async componentDidUpdate(prevProps) {
    // console.log('update')
    // console.log(this.props.computedMatch.params.productId, prevProps.computedMatch.params.productId)
    if(this.props.computedMatch.params.productId != prevProps.computedMatch.params.productId) {
      await frontload(this.props)
    }
  }

  render () {
    const {
      sendRaw, pageData, computedMatch, goods, images, categories, models, setPopup, domain, dealerGoods, favs, compares, favToggle, offers,
    } = this.props;
    const { status } = pageData;
    const { productId } = computedMatch.params;
    const good = goods && Object.values(goods).filter((one) => one.productId === productId)[0];
    let Component = null;
    switch (status) {
      case 'success':
        Component = Success;
        break;
      case 'error':
        Component = NotFound;
        break;
      default:
        Component = Loading;
        break;
    }
    return (
      <React.Fragment>
        <div className="hero hero_acc hero_small" style={{ backgroundImage: `url(${heroAcc})`, marginBottom: 20 }}>
          <Container>
            <span>
              Аксессуары
              <br />
              ŠKODA
            </span>
          </Container>
        </div>
        <FacetRev color="#fff" abs="true" style={{ marginTop: -20 }} />
        <Container>
          <GoodLd good={good} images={images} />
          <Component
            productId={productId}
            goods={goods}
            favs={favs}
            compares={compares}
            favToggle={favToggle}
            domain={domain}
            good={good}
            images={images}
            categories={categories}
            models={models}
            setPopup={setPopup}
            dealerGoods={dealerGoods}
            offers={offers}
            sendRaw={sendRaw}
          />
        </Container>
      </React.Fragment>

    );
  }
}

GoodPage.propTypes = {
  // dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  goods: makeSelectEntities('goods'),
  images: makeSelectEntities('images'),
  categories: makeSelectEntities('categories'),
  dealerGoods: makeSelectEntities('dealerGoods'),
  models: makeSelectEntities('models'),
  offers: makeSelectEntities('offers'),
  pageData: makeSelectPageData('good'),
  domain: makeSelectCurrentDomain(),
  favs: makeSelectFavs(),
  compares: makeSelectFavs(1),
});

function mapDispatchToProps (dispatch) {
  return {
    getGoodById: (payload) => dispatch(getGoodById(payload)),
    setPageData: (name, value) => dispatch(setPageData({ page: 'good', name, value })),
    setPopup: (id, opts) => dispatch(setPopup(id, opts)),
    favToggle: (payload) => dispatch(favToggle(payload)),
    sendRaw: (c, a, l) => dispatch(metricsActions.sendRaw(c, a, l))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withFrontload = frontloadConnect(frontload)

export default compose(
  withConnect,
  withFrontload,
)(withRouter(GoodPage));
