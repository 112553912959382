import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import styled, { css } from 'styled-components'
import hero from 'images/hero.jpg';
import bg2 from 'images/bg2.jpg';
import bg3 from 'images/bg3.jpg';

import card_mir from '!!file-loader?modules!images/card_mir.png';
import card_visa from '!!file-loader?modules!images/card_visa.png';
import card_jsb from '!!file-loader?modules!images/card_jsb.png';
import card_mastercard from '!!file-loader?modules!images/card_mastercard.png';
import ContainerOrig from 'components/Container';
import vars from 'config/styles'
import Slider from "react-slick";
import { Gapper } from 'components/Ui'
import Button from 'components/Button'
import { makeSelectDomainDealers, makeSelectCurrentDomain } from 'containers/App/selectors';
import { makeSelectEntities } from 'models/selectors';

export const SlickDots = css`
  .slick-dots {
    display: block;
    left: 40px;
    bottom: 65px;
    position: absolute;
    z-index: 1;    
    cursor: pointer;
    height: 16px;
    display: flex !important;
    justify-content: center;
    align-items: center;    
    list-style-type: none;
    padding: 0;
    margin: 0;
    @media (max-width: ${vars.bp.mobile}px) {
      display: none !important;
    }        
    li {
      position: relative;
      width: 26px;
      height: 16px;
      padding: 0;
      margin: 0;      
      button {
        width: 26px;
        height: 16px;
        padding: 0;
        font-size: 0;
        color: transparent;        
        position: relative;
        background: transparent;
        border: 0;
        cursor: pointer;
      }
      button:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0px;
        height: 0px;
        border: 3px solid rgb(255, 255, 255);
        border-radius: 50%;
        transition: border-width 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
        will-change: border-width;       
      }
    }
    li.slick-active {
      button {
      }
      button:after {
        border: 8px solid rgb(255, 255, 255);

      }
    }
  }
`

const AllWrapper = styled.div`
  position: relative;
  height: 560px;
  @media (max-width: ${vars.bp.tablet}px) {
    height: 50vw;
  }  
  @media (max-width: ${vars.bp.mobile}px) {
    height: auto;
  }    
  ${SlickDots}
`


const Wrapper = styled.div`
  background-position: center;
  height: 560px;
  background-size: cover;
  @media (max-width: ${vars.bp.tablet}px) {
    height: 50vw;
  }
  .container {
    position: relative;
    height: 100%;
  }
  span {
    position: absolute;
    font-size: 64px;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 0 0 8px #000;
    font-weight: bold;
    line-height: 64px;
    top: 20px;
    right: 20px;
    white-space: pre-line;
    h1 {
      font-size: 64px;
    }
    @media (max-width: ${vars.bp.tablet}px) {
      font-size: 40px;
      line-height: 40px;
    }
    @media (max-width: ${vars.bp.tablet}px) {
      font-size: 24px;
      line-height: 24px;
    }    
  }
`



const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  // autoplay: true,
  autoplaySpeed: 3000,  
};

const Title = styled.div`
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0px;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: #fff;
  font-size: 54px;
  line-height: 60px;
  @media (max-width: 1440px) {
    font-size: 48px;
    line-height: 54px;
  }    
  @media (max-width: 1024px) {
    font-size: 35px;
    line-height: 38px;
  }  
  @media (max-width: 768px) {
    color: rgb(73, 73, 73);
    font-size: 35px;
    line-height: 38px;    
  } 
`

const Subtitle = styled.div`
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0px;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: #fff;

  @media (max-width: 1440px) {
    font-size: 26px;
    line-height: 30px;
  }    
  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 21px;
  }
  @media (max-width: 768px) {
    color: rgb(73, 73, 73);
    font-size: 18px;
    line-height: 21px;
  } 
`

const Icons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: ${vars.bp.mobile}px) {
    justify-content: center;
  }      
  img {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: ${vars.bp.mobile}px) {
     margin: 0 12px;
    }
    &:last-child {
      margin: 0 12px;
    }

  }
`

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
  -webkit-box-pack: start;
  justify-content: flex-start;
  width: 100%;
  padding: 91px 78px 130px; 
  height: 100%;  
  @media (max-width: 1440px) {
    padding: 50px 52px 93px;
  }
  @media (max-width: 1024px) {
    padding: 45px 42px 62px;
  }
  @media (max-width: 768px) {
    padding: 16px 0;
    position: relative;
  } 

`

const Content = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }   
  .slick-active & {
    z-index: 10;
    /* position: relative; */
  }
`

const Slide = styled.div`
  position: relative;
`

const Banners = function ({ domain, payables, deliverables, domainDealers, dealers }) {
  const [hasPayment, setHasPayment] = useState(false)
  const [hasDelivery, setHasDelivery] = useState(false)
  const [bannerText, setBannerText] = useState('')
  useEffect(() => {
    const dealersArray = domainDealers.map(dealerId => dealers[dealerId])
    const hasp = !domain || !!dealersArray.find(dealer => payables[dealer.kod])
    const hasd = !domain || !!dealersArray.find(dealer => deliverables[dealer.kod] || dealer.freeCityDelivery)
    setHasPayment(hasp)
    setHasDelivery(hasd)
    let text = []
    if (hasp) {
      text.push('онлайн оплата')
    }
    if (hasd) {
      text.push('доставка')
    }    
    setBannerText(text.join(' и '))

  }, [domain, payables, deliverables, domainDealers])



  return (
    <AllWrapper>
      <Slider {...settings}>
        <div>
          <Wrapper style={{ backgroundImage: `url(${hero})`, backgroundPosition: 'center' }}>
            <ContainerOrig>
              <span>
                Свобода
                <br />
                выбирать
              </span>
            </ContainerOrig>
          </Wrapper>
        </div>
        <div>
          <Slide>
            <Wrapper style={{ background: `linear-gradient(90deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.1) 100%), url(${bg2})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
            </Wrapper>    
            <Container>
              <Content>
                <Title>ŠKODA Бонус</Title>
                <Subtitle>Будьте постоянны и платите меньше</Subtitle>
                <Button big target="_blank" href="https://lk.skoda-avto.ru/public/usloviya_polucheniya_ballov.pdf">Подробнее</Button>
              </Content>
            </Container>              
          </Slide>
        </div>
        {(hasPayment || hasDelivery) &&
          <div>
            <Slide>
              <Wrapper style={{ background: `linear-gradient(90deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.1) 100%), url(${bg3})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
              </Wrapper>
              <Container>
                <Content>
                  <Title>{bannerText}</Title>
                  <Subtitle>Не тратьте время, оставьте это нам</Subtitle>
                  <Icons>
                    <img src={card_mir} />
                    <img src={card_visa} />
                    <img src={card_mastercard} />
                    <img src={card_jsb} />
                  </Icons>                  
                  <Button big to='/pages/payment'>{(domain && !domain.isCg) ? 'Подробнее' : 'Посмотреть список дилеров'}</Button>
                </Content>
              </Container>              
            </Slide>
          </div>    
        }    
      </Slider>
    </AllWrapper>
  )
}



const mapStateToProps = createStructuredSelector({
  domain: makeSelectCurrentDomain(),
  domainDealers: makeSelectDomainDealers(),
  payables: makeSelectEntities('payables'),
  deliverables: makeSelectEntities('deliverables'),
  dealers: makeSelectEntities('dealers'),
});

function mapDispatchToProps(dispatch) {
  return {
    // setPopup: (id, opts) => dispatch(setPopup(id, opts)),
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(Banners);
