/**
 */

import {
  all, call, take, put, select, takeLatest, takeEvery, delay, fork
} from 'redux-saga/effects';


import { LOCATION_CHANGE } from 'connected-react-router';
import { SubmissionError } from 'redux-form/immutable';

import {
  auth, logout, getUser, userFavToggle, updateUser, getCurrentUser, getUserLocation
} from 'api/users';
import { addToCart, getCart } from 'api/cart';
import { getDomainInfo } from 'api/domains';
import { createOrder, dealerOrderCount, getOrder } from 'api/orders';
import { getAllRegionData as fetchAllRegionData } from 'api/regions';
import { getTabs } from 'api/tabs';
import { getModels, getAllModels } from 'api/models';
import { getCategories } from 'api/categories';
import { createNotification } from 'api/notifications';
import { ensureSsoAuthCheckIsComplete, logout as ssoLogout } from 'containers/Sso/saga';
import { hasSsoCreds } from 'containers/Sso/selectors';
import { makeSelectPreorderItems } from 'containers/OrderComments/selectors'
import { LOGOUT as SSO_LOGOUT } from 'containers/Sso/constants';

import { formatErrors, errorHandler, notify, _localStorage, isServer } from 'helpers';
import jwtDecode from 'jwt-decode';
import { normalize } from 'normalizr';
import { makeSelectCurrentUser, makeSelectHost, makeSelectDomainLoading, makeSelectIsAuthChecking } from 'containers/App/selectors';
import ReactGa from 'react-ga'
import { push } from 'connected-react-router';
import bonusSaga from 'containers/BonusPopup/saga'
import metricsSaga from './metricsSaga'

import { notifyStockSubmitMetrics, notifyStockSubmitSuccessMetrics } from 'containers/App/metricsActions'

import { setEntities, replaceEntities } from 'models/actions';
import {
  setCurrentUser, setCurrentCartId, getUser as getUserAction, getCart as getCartAction, setMenu,
  setCurrentDomainId, setPageData, setDealerOrderCount, getDealerOrderCount, getModels as getModelsAction, getCategories as getCategoriesAction,
  setFav, setCurrentRegionId, setLoading, setAuthError, completeDomainCheck, completeAuthCheck, setLocation
} from 'containers/App/actions'; 

import {
  Cart, User, Region, Domain, Model, Category, Order,
} from '../../models';

import { ROLES } from 'settings';

import _, { reject } from 'lodash';
import {
  CHECK_AUTH, ADD_TO_CART, GET_USER, GET_CART, MAKE_ORDER, GET_ALL_REGION_DATA, INITIAL_LOAD, INITIAL_SET, GET_DEALER_ORDER_COUNT,
  GET_MODELS, GET_CATEGORIES, FAV_TOGGLE, SET_CURRENT_REGION_ID, LOGIN, LOGOUT, NOTIFY_STOCK, COMPLETE_DOMAIN_CHECK, GET_ORDER, COMPLETE_AUTH_CHECK,
  GET_TABS
} from './constants';
/**
 */

export function * initGen (action) {
  const { payload: { resolve, reject } } = action;
  try {
    // console.log('start')
    // yield put(getModelsAction());
    // yield put(getCategoriesAction());
    const [res1, res2, domain] = yield all([
      call(getModelsGen),
      call(getCategoriesGen),
      call(apiRequest, getDomainInfo),
      call(getLocationGen)
    ])

    if (!_.isEmpty(domain)) {
      const normalized = normalize(domain, Domain);
      yield put(setEntities(normalized));
      yield put(setCurrentDomainId(domain.id));
    }
    // console.log("complete")
    yield put(completeDomainCheck()) 
    resolve()
  } catch (e) {
    // console.log("complete2")

    yield put(completeDomainCheck())
    errorHandler(e);
    reject()
  }
}

export function* ensureDomainCheckIsComplete() {
  if (yield select(makeSelectDomainLoading())) {
    yield take(COMPLETE_DOMAIN_CHECK);
  }
}

export function * apiRequestForce (apiFn, ...params) {
  // console.log({ params })
  const host = yield select(makeSelectHost())
  // console.log({ host })

  const result = apiFn.apply(null, params)
  // console.log(result)
  return yield call(result, { host })
  // body...
}

export function * apiRequest (apiFn, ...params) {
  // console.log({ params })

  const host = yield select(makeSelectHost())
  // console.log({ host })
  if(!isServer) {
    // console.log('api before')
    yield call(ensureAuthCheckIsComplete)
    // console.log('api after')
  }

  const result = apiFn.apply(null, params)
  // console.log(result)
  return yield call(result, { host })
  // body...
}

export function * initSetGen (action) {
  try {
    const cur = JSON.parse(_localStorage.getItem('favs')) || [];
    yield put(setFav(cur));
    const comps = JSON.parse(_localStorage.getItem('compares')) || [];
    yield put(setFav(comps, 1));

    const regionId = _localStorage.getItem('regionId');
    yield put(setCurrentRegionId(regionId, false));
  } catch(e) {
    errorHandler(e);
  }
}


// export function * loginGen (action) {
//   const { payload: { data, resolve, reject } } = action;
//   try {
//     const cartId = yield select((state) => state.getIn(['global', 'cartId']));
//     const { token, user } = yield call(auth, { ...data.toJS(), cartId });
//     _localStorage.setItem('auth_token', token);
//     if (user.cart && user.cart.id) {
//       _localStorage.setItem('cartId', user.cart.id);
//       yield put(setCurrentCartId(user.cart.id));
//     }
//     const normalized = normalize(user, User);
//     // const currentUser = normalized.entities.users[normalized.result];
//     yield put(setCurrentUser(user.id));
//     yield put(setEntities(normalized));
//     if (user.role === ROLES.DEALER) {
//       yield put(getDealerOrderCount(user.dealerId));
//     }
//     resolve();
//   } catch (e) {
//     errorHandler(e);
//     const errors = formatErrors(e);
//     const formError = new SubmissionError(errors);
//     reject(formError);
//   }
// }





export function* logoutGen(action) {
  console.log("user logging out")
  // console.log(action)
  try {
    const user = yield call(logout);
  } catch (e) {
    // yield put(setError, user);
    console.log(e, e.message)
  }
  // _localStorage.removeItem('auth_token');
  // _localStorage.removeItem('cartId');
  yield put(setCurrentUser(null));
  yield put(setCurrentCartId(null));
  yield put(setMenu(null));
  yield put(setDealerOrderCount(0));
  _localStorage.removeItem('user');
  _localStorage.removeItem('cartId');
  if(!action || action.type !== SSO_LOGOUT) {
    yield ssoLogout();
  }
  // yield put(push('/'))
  notify('Выход', 'Выход успешно выполнен')
}

// export function * checkAuthGen () {

//   if (token) {
//     const user = jwtDecode(token);

//     yield put(getUserAction(user.id));
//   } else if (cartId) {
//     yield put(getCartAction(cartId));
//   }
// }
export function* checkAuthGen() {
  console.log('check auth')
  if(isServer) {
    yield put(completeAuthCheck());
    return
  }
  try {
    const cachedUser = JSON.parse(_localStorage.getItem("user"));
    // console.log("token: ", token);
    if (cachedUser) {
      const normalized = normalize(cachedUser, User);
      yield put(setEntities(normalized));
      yield put(setCurrentUser(cachedUser.id));
    }
  } catch(e) {
    console.error(e)

  }

  const cartId = _localStorage.getItem('cartId') === 'null' ? null : _localStorage.getItem('cartId');
  if (cartId) { 
    yield put(setCurrentCartId(cartId)); 
    yield put(getCartAction(cartId));
  }

  try {
    console.log('waiting for check')
    yield call(ensureSsoAuthCheckIsComplete);
    console.log('sso check complete')
    const token = _localStorage.getItem('access_token');
    if(token) {
      const user = yield call(apiRequestForce, getCurrentUser);
      // console.log(user)
      const normalized_user = normalize(user, User)
      yield put(setCurrentUser(user.id));    
      _localStorage.setItem('user', JSON.stringify(user));
      yield put(setEntities(normalized_user))
    }

    yield put(setLoading(false));

    console.log('before comple')
    // yield delay(5000)      
    yield put(completeAuthCheck());
    console.log('comple!')    
  } catch (e) {
    // console.error(e)
    yield put(setLoading(false));
    console.log('before comple')
    // yield delay(5000)          
    yield put(completeAuthCheck());
    console.log('comple!')
    const hasIdToken = yield select(hasSsoCreds());
    if(hasIdToken) {
      yield put(setAuthError(true))
      yield put(push('/auth_error'))
    } else {
      yield call(logoutGen);
      notify('Выход', 'Сеанс авторизации окончен', 'error');
    }
    // }
  }
}


export function* ensureAuthCheckIsComplete() {
  if (yield select(makeSelectIsAuthChecking())) {
    yield take(COMPLETE_AUTH_CHECK);
  }
}

export function * getUserGen (action) {
  const { userId } = action;
  try {
    const user = yield call(apiRequest, getUser, userId);
    yield put(setEntities(normalize(user, User)));
    yield put(setCurrentUser(user.id));
    if (user.role === ROLES.DEALER) {
      yield put(getDealerOrderCount(user.dealerId));
    }
    return user
  } catch (e) {
    errorHandler(e);
    return false
  }
}

export function * getCartGen (action) {
  const { cartId } = action;
  try {
    const cart = yield call(apiRequest, getCart, cartId);
    yield put(setEntities(normalize(cart, Cart)));
  } catch (e) {
    errorHandler(e);
  }
}

export function * addToCartGen (action) {
  const { payload: { data, resolve, reject } } = action;
  const { productId, dealerId, quantity } = data;
  const cartId = yield select((state) => state.getIn(['global', 'cartId']));
  try {
    const cart = yield call(apiRequest, addToCart, {
      productId, dealerId, quantity, cartId,
    });
    yield put(setCurrentCartId(cart.id));
    _localStorage.setItem('cartId', cart.id);
    const normalizedCart = normalize(cart, Cart);
    yield put(replaceEntities(normalizedCart, 'carts'));
    yield put(replaceEntities(normalizedCart, 'items'));
    resolve();
  } catch (err) {
    reject(err);
  }
}

export function * makeOrderGen (action) {
  const { payload: { resolve, reject } } = action;
  const cartId = yield select((state) => state.getIn(['global', 'cartId']));
  const preorderItems = yield select(makeSelectPreorderItems())
  try {
    const orders = yield call(apiRequest, createOrder, { cartId, preorderItems });
    const normalized = normalize(orders, [Order]);
    yield put(setEntities(normalized));
    _localStorage.setItem('cartId', null);
    yield put(setCurrentCartId(null));
    resolve(orders.map(o => o.id).join(','));
  } catch (err) {
    const errors = formatErrors(err)
    console.log(errors)
    reject(errors);
  }
}



export function * favToggleGen (action) {
  const { payload: { data, resolve, reject } } = action;
  const { goodId, type } = data;
  
  const currentUser = yield select(makeSelectCurrentUser());
  try {
    if (currentUser) {
      const user = yield call(apiRequest, userFavToggle, goodId, type);
      yield put(replaceEntities(normalize(user, User), 'favs'));
    } else {
      const tp = type === 1 ? 'compares' : 'favs';
      let cur = JSON.parse(_localStorage.getItem(tp)) || [];
      if (cur.includes(goodId)) {
        cur = cur.filter((item) => item !== goodId);
      } else {
        cur.push(goodId);
      }

      _localStorage.setItem(tp, JSON.stringify(cur));
    }
    resolve();
  } catch (err) {
    errorHandler(err);
    reject(err);
  }
}

export function * setRegionGen (action) {
  const { regionId, query } = action;
  const currentUser = yield select(makeSelectCurrentUser());
  try {
    if (query === true) {
      if (currentUser) {
        const user = yield call(apiRequest, updateUser, { id: currentUser.id, regionId });
        yield put(setEntities(normalize(user, User)));
      } else {
        _localStorage.setItem('regionId', regionId);
      }
      // resolve();
    }
  } catch (err) {
    errorHandler(err);
    // reject(err);
  }
}

export function * getAllRegionData (action) {
  try {
    const regionData = yield call(apiRequest, fetchAllRegionData);
    yield put(setEntities(normalize(regionData, [Region])));
  } catch (e) {
    errorHandler(e);
  }
}

export function * getDealerOrderCountGen (action) {
  const { dealerId } = action;
  try {
    const count = yield call(apiRequest, dealerOrderCount, dealerId);
    yield put(setDealerOrderCount(count.count));
  } catch (e) {
    errorHandler(e);
  }
}

export function * notifyStockGen (action) {
  const { payload: {resolve, reject, data} } = action;
  try {
    yield put(notifyStockSubmitMetrics(data.productId))
    yield call(apiRequest, createNotification, data)
    yield put(notifyStockSubmitSuccessMetrics(data.productId))
    resolve()
  } catch (e) {
    const errors = formatErrors(e);
    const formError = new SubmissionError(errors);
    reject(formError);
  }
}

export function* forceAuthError(action) {
  const { payload } = action;
  if(isServer) return
  yield call(ensureSsoAuthCheckIsComplete);
  const user = yield select(makeSelectCurrentUser())
  const hasIdToken = yield select(hasSsoCreds());
  if(!user && hasIdToken && payload.location.pathname !== '/auth_error') {
    console.log('pushing')
    yield delay(50)
    yield put(push('/auth_error'));
  }
}

export function * getModelsGen (action) {
  try {
    const models = yield call(apiRequest, (action || {}).all ? getAllModels : getModels);
    const normalizedEntities = normalize(models, [Model]);
    yield put(setEntities(normalizedEntities));
  } catch (e) {
    errorHandler(e);
  }
}

export function * getLocationGen (action) {
  try {
    const location = yield call(apiRequest, getUserLocation)
    // console.log({ location })
    yield put(setLocation(location.result))
  } catch(e) {
    
  }
}

export function * getOrderGen (action) {
  const { id, resolve, reject } = action.payload
  try {
    const order = yield call(apiRequest, getOrder, id);
    const normalized = normalize(order, Order);
    yield put(setEntities(normalized));
    resolve && resolve()
  } catch (e) {
    reject && reject()
  }
}


export function * getCategoriesGen (action) {
  try {
    const categories = yield call(apiRequest, getCategories);
    const normalizedEntities = normalize(categories, [Category]);
    yield put(setEntities(normalizedEntities));
  } catch (e) {
    errorHandler(e);
  }
}

export function* getTabsGen(action) {
  const { payload } = action;
  const { resolve, reject } = payload;
  try {
    const data = yield call(apiRequest, getTabs)
    resolve(data)
  } catch (e) {
    reject()
  }
}


export function * closeMenuOnLocationChange (action) {
  // console.log(action)
  window.scrollTo(0, 0);
  yield put(setMenu(null));
}

export function * trackPageChange (action) {
  if (process.env.NODE_ENV !== 'production') return
  try {
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`
    ReactGa.set({
      page: nextPage,
      // ...options
    })
    ReactGa.pageview(nextPage)  

    window.dispatchEvent(
      new window.CustomEvent('HistoryChange', {
        detail: {
          analyticsContext: {
            href: nextPage,
          },
        },
      })
    ); 

  } catch(e) {

  }
}

/**
 * Root saga manages watcher lifecycle
 */


export default function * rootSaga () {
  yield all([
    yield takeLatest(INITIAL_LOAD, initGen),
    yield takeEvery(LOCATION_CHANGE, forceAuthError),
    yield takeLatest(INITIAL_SET, initSetGen),
    yield takeEvery(LOGOUT, logoutGen),
    yield takeEvery(SSO_LOGOUT, logoutGen),
    yield takeEvery(CHECK_AUTH, checkAuthGen),
    yield takeEvery(ADD_TO_CART, addToCartGen),
    yield takeLatest(GET_USER, getUserGen),
    yield takeLatest(GET_CART, getCartGen),
    yield takeEvery(MAKE_ORDER, makeOrderGen),
    yield takeLatest(GET_ALL_REGION_DATA, getAllRegionData),
    yield takeEvery(LOCATION_CHANGE, closeMenuOnLocationChange),
    yield takeEvery(LOCATION_CHANGE, trackPageChange),
    yield takeEvery(GET_DEALER_ORDER_COUNT, getDealerOrderCountGen),
    yield takeEvery(GET_MODELS, getModelsGen),
    yield takeEvery(GET_CATEGORIES, getCategoriesGen),
    yield takeEvery(FAV_TOGGLE, favToggleGen),
    yield takeEvery(SET_CURRENT_REGION_ID, setRegionGen),
    yield takeEvery(NOTIFY_STOCK, notifyStockGen),
    yield takeEvery(GET_ORDER, getOrderGen),
    yield takeEvery(GET_TABS, getTabsGen),
    yield fork(bonusSaga),
    yield fork(metricsSaga),
  ]);
}

