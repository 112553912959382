import React from 'react'
import styled from 'styled-components'

import Header from 'components/Header'
import SubHeader from 'components/SubHeader'
import vars from 'config/styles'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`

const Padder = styled.div`
  height: 113px;
  @media (max-width: ${vars.bp.mobile}px) {
    height: 99px;
  }    
`

const Top = () => {

  return (
    <>
      <Padder />
      <Wrapper>
        <Header />
        <SubHeader />
      </Wrapper>
    </>
  )
}

export default Top