/* istanbul ignore file */
import { apicall } from 'utils/api';

export function getTabs () {
  return apicall('tabs');
}

export function createTab (data) {
  return apicall('tabs', { method: 'POST', body: JSON.stringify({ tab: data }) });
}

export function updateTab (data) {
  const { id, ...rest } = data;
  return apicall(`tabs/${id}`, { method: 'PATCH', body: JSON.stringify({ tab: rest }) });
}

export function deleteTab(id) {
  return apicall(`tabs/${id}`, { method: 'DELETE' });
}
