
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import 'css/components/breadcrumbs.sass';
import { Helmet } from 'react-helmet-async';
import { makeSelectHost } from 'containers/App/selectors';
import { useSelector } from 'react-redux'

const BreadCrumbs = ({ items }) => {
  const host = useSelector(makeSelectHost())
  console.log({ items })
  const ld = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement":
      [
        {
          "@type": "ListItem",
          "position": 0,
          "item":
          {
            "@id": host,
            "name": "Главная"
          }
        }
      ]
  }
  items.map((item, i) => {
    ld.itemListElement.push({
      "@type": "ListItem",
      "position": i + 1,
      "item":
      {
        "@id": `${host}${item.link}${items.length === i + 1 ? '#last' : ''}`,
        "name": item.title
      }
    })
  })
  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ld)}</script>
      </Helmet>
      <div className="breadcrumbs">
        <div className="breadcrumbs__item">
          <Link to='/'>
            Главная
          </Link>
        </div>    
        <div className="breadcrumbs__delimiter">
          <FontAwesomeIcon icon={faChevronRight} />
        </div>    
        { items.map((one, i) => (
          <React.Fragment key={`bc-${one.link}`}>
            <div className="breadcrumbs__item">
              {!one.last && one.link &&
              (
                <Link to={one.link} onClick={() => { one.onClick && one.onClick() }}>
                  {one.title}
                </Link>
              )
              }
              {one.last &&
              (
                <span>
                  {one.title}
                </span>
              )
              }
            </div>
            { items.length - 1 > i &&
            (
              <div className="breadcrumbs__delimiter">
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            )
            }
          </React.Fragment>
        ))}
      </div>
    </>
  )
};

BreadCrumbs.propTypes = {
  items: PropTypes.array,
};

export default BreadCrumbs;
