import React from 'react'

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import {
  Field, reduxForm,
} from 'redux-form/immutable';

import styled from 'styled-components'

import { setPopup } from 'containers/App/actions'
import { USER_AGREEMENT, AGREEMENT2 } from 'components/Popup/constants'
import { setPageData } from 'containers/App/actions';

import { makeSelectEntitiesAsOptions } from 'models/selectors'
import { makeSelectGeoDealers } from 'containers/App/selectors'
import PodborField from 'components/Form/components/PodborField'

import Row from 'components/Row'
import Col from 'components/Col'
import Button from 'components/Button'
import { checkBoxField } from 'components/Form/components';
import SelectField from 'components/Fields/SelectField';
import { InputField, MaskField } from 'components/Fields'
import { H1, Text3, Gapper, Flex } from 'components/Ui'
import { addToPodbor } from 'containers/Parts/actions'
import vars from 'config/styles'
import * as metricsActions from 'containers/App/metricsActions'

const validate = (values) => {
  const errors = {};

  return errors;
};

const Wrapper = styled.div`
  padding: 0 30px;
  max-width: 700px;
  @media (max-width: ${vars.bp.mobile}px) {
    padding: 0;
  }  
`


class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 0
    }
  }

  componentDidMount() {
    this.props.sendRaw('RequestPartsForm', 'open_form')
  }

  componentWillUnmount() {
    this.props.sendRaw('RequestPartsForm', 'close_form')
  }


  render() {
    const { handleSubmit, submitting, forDealer, dealerId, parts, setPageData, setPopup, dealerOptions, addToPodbor, sendRaw } = this.props
    return (
      <form onSubmit={handleSubmit}>
        <Gapper gap={60}>
          <H1 center>подбор оригинальных деталей</H1>
          {this.state.step === 0 &&
            <Gapper gap={20}>
              <Text3 center>Выберите дилера, добавьте необходимые детали и укажите свои контактные данные.</Text3>
              <Wrapper>       
                <Field name='dealerId' onChange={() => sendRaw('RequestPartsForm', 'choose_dealer')} component={SelectField} options={dealerOptions} label="Дилер" />
                <Field name='parts' sendRaw={sendRaw} addToPodbor={addToPodbor} component={PodborField} forDealer={forDealer} onFieldNullify={() => {setPageData('podbor', null); setPageData('status', 'initial')} }/>
                <Flex right space={10}>
                  <Button secondary onClick={() => { sendRaw('RequestPartsForm', 'click_cancel'); setPopup(null)}}>
                    Отмена
                  </Button>
                  <Button disabled={!dealerId || !parts || parts.length === 0} onClick={() => { sendRaw('RequestPartsForm', 'click_further'); dealerId && this.setState({ step: 1 })}}>
                    Далее
                  </Button>
                </Flex>
              </Wrapper>
            </Gapper>
          }
          {this.state.step === 1 && (
            <Wrapper>
              <Row>
                <Col n={6} sm={12}>
                  <Field onChange={e => sendRaw('RequestsPartsForm', 'input_name', e)} name='name' required component={InputField} type='text' label='Имя' />
                </Col>
                <Col n={6} sm={12}>
                  <Field onChange={e => sendRaw('RequestsPartsForm', 'input_surname', e)} name='surname' required component={InputField} type='text' label='Фамилия' />
                </Col>          
                <Col n={6} sm={12}>
                  <Field onChange={e => sendRaw('RequestsPartsForm', 'input_phone', e)} type="text" required name="phone" component={MaskField} mask='+{7}(000)000-00-00' label="Телефон" />
                </Col>       
                <Col n={6} sm={12}>
                  <Field onChange={e => sendRaw('RequestsPartsForm', 'input_email', e)} name='email' required  component={InputField} type='text' label='E-mail' />
                </Col>       
                <Col n={6} sm={12}>
                  <Field onChange={e => sendRaw('RequestsPartsForm', 'input_vin', e)} name='vin' component={InputField} type='text' label='VIN' />
                </Col>       
                <Col n={6} sm={12}>
                  <Field onChange={e => sendRaw('RequestsPartsForm', 'input_comment', e)} name='comment' component={InputField} type='text' label='Комментарий' />
                </Col>                                                          
              </Row>
              <Text3 style={{marginBottom: 20}}>
                VIN необязателен, но поможет ускорить обработку Вашей заявки сотрудником ДЦ
              </Text3>
              <Field required onChange={e => sendRaw('RequestsPartsForm', 'confidential', e.target.value)} name='accepted' component={checkBoxField} type='checkbox'>
                <>
                  Я согласен на&nbsp; 
                  <em className='link' onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.setPopup(USER_AGREEMENT)}}>использование моих персональных данных</em>
                </>
              </Field>
              <Field name='ad_subscribed' onChange={e => sendRaw('RequestsPartsForm', 'advertisement', e.target.value)} component={checkBoxField} type='checkbox'>
                <>
                  Я согласен на&nbsp; 
                  <em className='link' onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.setPopup(AGREEMENT2)}}>рекламную коммуникацию</em>
                </>
              </Field>                 
              <Flex right space={20}>
                <Button secondary onClick={() => { sendRaw('RequestsPartsForm', 'click_back'); this.setState({ step: 0 })}}>
                  Назад
                </Button>
                <Button loading={submitting} type="submit">
                  Отправить запрос
                </Button>
              </Flex>
            </Wrapper>
          )}
          </Gapper>
      </form>
    )
  }
}


function mapDispatchToProps (dispatch) {
  return {
    setPopup: (id, opts) => dispatch(setPopup(id, opts)),
    setPageData: (name, value) => dispatch(setPageData({ page: 'parts', name, value })),
    addToPodbor: item => dispatch(addToPodbor(item)),
    sendRaw: (c, a, l) => dispatch(metricsActions.sendRaw(c, a, l))
  };
}

const mapStateToProps = createStructuredSelector({
  // dealerOptions: makeSelectEntitiesAsOptions('dealers'),
  dealerOptions: makeSelectGeoDealers(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withForm = reduxForm({ form: 'parts-podbor', validate });

export default compose(
  withConnect,
  withForm,
)(Form);


// export default Form