/**
*
* DealerOrder
*
*/

import React from 'react';
import styled, { css } from 'styled-components';
import vars from 'config/styles'

import Box, { Inner } from 'components/Box'
import Card from 'components/Card'
import CardContainer from 'components/CardContainer'
import DealerInfoBox from 'components/DealerInfoBox'
import OrderItems from 'components/OrderItems'
import Radio from 'components/Radio'
import DeliveryInfo from 'components/DeliveryInfo'
import { payTypes, deliveryTypes } from 'settings'

import { H7, H4, A, Gapper, Flex, Text2, Text4 } from 'components/Ui'



const Card1 = styled(Card)`
  order: 0;
  @media (max-width: ${vars.bp.tablet}px) {
    order: 1;
  }    
`

const Card2 = styled(Card)`
  order: 1;
  @media (max-width: ${vars.bp.tablet}px) {
    order: 0;
  }    
`

const Status = styled.div`
  text-align: center;
  font-size: 20px;
  padding: 10px 0;
`

const DealerOrder = (props) => {
  const {
    dealer, items, setPopup, moditem, removeBonus, order, showDealerInfo = true
  } = props;
  const payType = order.payType || 0
  const deliveryType = order.deliveryType || 0
  const delivery = order.delivery
  // const comment = preorderItems.comment || ''
  return (
    <CardContainer> 
      <Card1 d={showDealerInfo ? 8 : 12} t={12}>
        <Gapper gap={24}>
          <OrderItems 
            items={items} 
            moditem={moditem} 
            delivery={delivery}
            setPopup={setPopup}
            removeBonus={removeBonus}
            payType={payType}
            deliveryType={deliveryType}
            dimensionsPresent={order.dimensions}
          />

          <Box bged>
            <Inner small>
              <Gapper gap={24}>
                <H7>Способ оплаты</H7>
                <Text2 style={{ marginRight: 40 }}>
                  {payType === payTypes.ON_PICK && "Оплата при получении"}
                  {payType === payTypes.ONLINE && "Оплата онлайн"}
                </Text2>
              </Gapper>
            </Inner>
          </Box>
          <Box bged>
            <Inner small>
              <Gapper gap={24}>
                <H7>Способ получения</H7>
                <Text2 style={{ marginRight: 40 }}>
                  {deliveryType === deliveryTypes.PICK && "Самовывоз"}
                  {deliveryType === deliveryTypes.DELIVERY && "Доставка"}
                  {deliveryType === deliveryTypes.CITY_DELIVERY && "Бесплатная доставка по городу"}
                </Text2>
                {deliveryType === deliveryTypes.CITY_DELIVERY && <Text4>Адрес: {order.address}</Text4>}

              </Gapper>
            </Inner>
          </Box>
        </Gapper>
{/*
        <Box>
          <Inner small>
            <Gapper gap={24}>
              <H7>Оплата</H7>
              <Flex>
                <Text2 style={{marginRight: 40}}>
                  {payType===0 && "Оплата при получении"}
                  {payType===1 && "Оплата онлайн"}
                </Text2>                    
              </Flex>
              <H7>Доставка</H7>
              <Flex>
                <Text2 style={{marginRight: 40}}>
                  {deliveryType===0 && "Самовывоз"}
                  {deliveryType===1 && 
                    <>
                      <DeliveryInfo delivery={order.delivery}/>
                      {order.dimensions === false && 
                        <div>
                          <span style={{color: 'red'}}>!</span> Расчет доставки возможен только после проверки заказа дилером.
                        </div>
                      }
                    </>
                  }
                </Text2>                    
              </Flex>                
            </Gapper>
          </Inner >
        </Box>*/}
      </Card1>
      {showDealerInfo && 
        <Card2 d={4} t={12}>
          <DealerInfoBox dealer={dealer} />
        </Card2>        
      }
    </CardContainer>
  )

}


export default DealerOrder; 