let apiServer = process.env.API_URL || 'https://apiparts.skoda-avto.ru/';
let domainFront = process.env.DOMAIN_FRONT || 'parts.skoda-avto.ru';
let appUrl = process.env.CLIENT_URL || 'https://parts.skoda-avto.ru';
let deliveryHost = process.env.DELIVERY_HOST || 'https://apiparts.skoda-avto.ru/api/v1/delivery/'


if (process.env.NODE_ENV === 'development') {
  // apiServer = 'http://192.168.56.101:3000/';
  // domainFront = '192.168.56.101';
  apiServer = 'http://192.168.56.102:3000/';
  // apiServer = 'https://partsskodaapi.yuti.ru/';

  domainFront = '192.168.56.102';
  appUrl = 'http://192.168.56.102:3001';
  deliveryHost = 'http://192.168.56.102:3020/api/v1/delivery/'
  // apiServer = 'http://localhost:3000/';
  // domainFront = 'localhost';
}

// console.log({df: process.env.DOMAIN_FRONT, apiServer, domainFront})

// ---
const apiSuffix = 'api/v1/';
export const ssoBaseUrl = process.env.SSO_BASE_URL || 'https://idp-test.vwgroup.ru'
export const clientId = process.env.CLIENT_ID || 'skodaparts'
export const ssoAuthPath = '/auth'
export const serverUrl = 'https://skoda-eshop.s3.eu-central-1.amazonaws.com/'; // apiServer;
export const apiUrl = apiServer + apiSuffix;
export const frontDomain = domainFront;
export const app_url = appUrl;
export { deliveryHost }
// const googleApiKey = "AIzaSyBhAVpO4wYqWx2wnwxj5fqh446crRYuDCg"
// const recaptcha_key = "6LfoVyMUAAAAABVfvOrKZZDbCdsZfVadsCwzyfn3"
