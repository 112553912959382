/* istanbul ignore file */
/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 */

export const DEFAULT_LOCALE = 'en';
// export const API_REQUEST = 'SkodaParts/APP/API_REQUEST';
export const LOGIN = 'SkodaParts/App/LOGIN';
export const LOGOUT = 'SkodaParts/App/LOGOUT';
export const SET_PAGE_DATA = 'SkodaParts/APP/SET_PAGE_DATA';
export const SET_CURRENT_USER = 'SkodaParts/APP/SET_CURRENT_USER';
export const SET_CURRENT_CART_ID = 'SkodaParts/APP/SET_CURRENT_CART_ID';
export const SET_CURRENT_REGION_ID = 'SkodaParts/APP/SET_CURRENT_REGION_ID';
export const CHECK_AUTH = 'SkodaParts/APP/CHECK_AUTH';
export const ADD_TO_CART = 'SkodaParts/APP/ADD_TO_CART';
export const CHECKOUT_ORDER = 'SkodaParts/APP/CHECKOUT_ORDER';
export const GET_USER = 'SkodaParts/APP/GET_USER';
export const GET_CART = 'SkodaParts/APP/GET_CART';
export const MAKE_ORDER = 'SkodaParts/APP/MAKE_ORDER';
export const GET_ALL_REGION_DATA = 'SkodaParts/APP/GET_ALL_REGION_DATA';
export const SET_POPUP = 'SkodaParts/APP/SET_POPUP';
export const SET_MENU = 'SkodaParts/APP/SET_MENU';
export const INITIAL_LOAD = 'SkodaParts/APP/INITIAL_LOAD';
export const INITIAL_SET = 'SkodaParts/APP/INITIAL_SET';
export const SET_CURRENT_DOMAIN_ID = 'SkodaParts/APP/SET_CURRENT_DOMAIN_ID';
export const GET_DEALER_ORDER_COUNT = 'SkodaParts/APP/GET_DEALER_ORDER_COUNT';
export const SET_DEALER_ORDER_COUNT = 'SkodaParts/APP/SET_DEALER_ORDER_COUNT';
export const GET_MODELS = 'SkodaParts/APP/GET_MODELS';
export const GET_CATEGORIES = 'SkodaParts/APP/GET_CATEGORIES';
export const FAV_TOGGLE = 'SkodaParts/APP/FAV_TOGGLE';
export const SET_FAV = 'SkodaParts/APP/SET_FAV';
export const NOTIFY_STOCK = 'SkodaParts/APP/NOTIFY_STOCK';
export const SET_LOADING = 'SkodaParts/APP/SET_LOADING';
export const SET_DOMAIN_LOADING = 'SkodaParts/APP/SET_DOMAIN_LOADING';
export const SET_AUTH_ERROR = 'SkodaParts/APP/SET_AUTH_ERROR';
export const SET_MESSAGES_SHOW = 'SkodaParts/APP/SET_MESSAGES_SHOW';
export const COMPLETE_DOMAIN_CHECK = 'SkodaParts/APP/COMPLETE_DOMAIN_CHECK';
export const GET_ORDER = 'SkodaParts/APP/GET_ORDER';
export const COMPLETE_AUTH_CHECK = 'SkodaParts/APP/COMPLETE_AUTH_CHECK';
export const SET_LOCATION = 'SkodaParts/APP/SET_LOCATION';
export const GET_TABS = 'SkodaParts/APP/GET_TABS';
