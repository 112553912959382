import { createSelector } from 'reselect';
import { selectEntities } from 'models/selectors';
import { frontDomain } from '../../config';
const selectSso = (state) => state.get('sso');

const selectRoute = (state) => state.get('router');

const makeSelectLocation = () => createSelector(
  selectRoute,
  (routeState) => routeState.get('location').toJS()
);

const selectGlobal = (state) => state.get('global');
const selectPages = (state) => state.get('global').get('pages');

const makeSelectMainDomain = () => createSelector(
  selectGlobal,
  (globalState) => { return globalState.get('domain') === frontDomain || process.env.NODE_ENV === 'test'}
);

const makeSelectHost = () => createSelector(
  selectGlobal,
  (globalState) => globalState.get('host')
);

const makeSelectMessagesShow = () => createSelector(
  selectGlobal,
  (globalState) => globalState.get('messagesShow')
);


const makeSelectBrowserIsOk = () => createSelector(
  selectGlobal,
  (globalState) => globalState.get('browserIsOk')
);


const makeSelectIsDealerDomain = () => createSelector(
  selectGlobal,
  (globalState) => globalState.get('domain') !== frontDomain
);

const makeSelectPageData = (page) => createSelector(
  selectPages,
  (pagesState) => {
    if (pagesState && pagesState.get(page)) {
      return pagesState.get(page).toJS();
    }
    return {};
  }
);

const makeSelectLoading = () => createSelector(
  selectGlobal,
  (globalState) => globalState.get('loading')
);

const makeSelectDomainLoading = () => createSelector(
  selectGlobal,
  (globalState) => globalState.get('domainLoading')
);

// const makeSelectCurrentUser = () => createSelector(
//   selectGlobal,
//   selectEntities,
//   (globalState, entitiesState) => {
//     const userId = globalState && globalState.get('currentUserId');
//     if (!userId) return null;
//     const users = entitiesState.get('users') && entitiesState.get('users').toJS();
//     const user = users && users[userId];
//     return user;
//   }
// );

const makeSelectCurrentUser = () => createSelector(
  selectGlobal,
  selectEntities,
  selectSso,
  (globalState, entitiesState, ssoState) => {
    const userId = globalState && globalState.get('currentUserId');
    if (!userId) return null;
    const users = entitiesState.get('users') && entitiesState.get('users').toJS();
    const user = users && users[userId];
    const idToken = ssoState.get('user');
    // console.log(idToken)
    return { ...user, ...idToken };
  }
);


const makeSelectFavs = (type = 0) => createSelector(
  selectGlobal,
  selectEntities,
  (globalState, entitiesState) => {
    const userId = globalState.get('currentUserId');
    if (!userId) {
      const tp = type === 1 ? 'compares' : 'favs';
      return globalState.get(tp).toJS() || [];
    }
    const favs = entitiesState.get('favs') && entitiesState.get('favs').toJS();
    return favs ? Object.values(favs).filter((a) => (a.userId === userId && a.type === type)).map((a) => a.goodId) : [];
  }
);

const makeSelectCurrentCartId = () => createSelector(
  selectGlobal,
  (globalState) => globalState && globalState.get('cartId'),
);

const selectCartModItems = () => createSelector(
  makeSelectCurrentCartId(),
  selectEntities,
  (cartId, entitiesState) => {
    const carts = entitiesState && entitiesState.get('carts') && entitiesState.get('carts').toJS()
    const cart = carts && cartId && carts[cartId] || {}
    const moditems = cart.moditems || []
    return moditems.reduce((acc, cur) => ({...acc, [cur.dealerId]: cur}) , {})
  }
)

const selectCartItems = () => createSelector(
  makeSelectCurrentCartId(),
  selectEntities,
  (cartId, entitiesState) => {
    const items = entitiesState && entitiesState.get('items') && entitiesState.get('items').toJS()
    return Object.values(items).filter(item => item.cartId === cartId)
    // return items.reduce((acc, cur) => ({ ...acc, [cur.dealerId]: cur }), {})
  }
)

const selectCartDeliveries = () => createSelector(
  makeSelectCurrentCartId(),
  selectEntities,
  (cartId, entitiesState) => {
    const carts = entitiesState && entitiesState.get('carts') && entitiesState.get('carts').toJS()
    const cart = carts && cartId && carts[cartId] || {}
    const deliveries = cart.deliveries || []
    return deliveries.reduce((acc, cur) => ({...acc, [cur.dealerId]: cur}) , {})
  }
)

const makeSelectCurrentRegionId = () => createSelector(
  selectGlobal,
  makeSelectCurrentUser(),
  (globalState, currentUser) => {
    if (!currentUser) {
      return globalState.get('regionId');
    }
    return currentUser && currentUser.regionId;
  }
);

const makeSelectDealerOrderCount = () => createSelector(
  selectGlobal,
  (globalState) => globalState.get('dealerOrderCount'),
);

const makeSelectUserLocation = () => createSelector(
  selectGlobal,
  (globalState) => globalState.get('location'),
);

function calcCrow(lat1, lon1, lat2, lon2) {
  // console.log({ lat1, lon1, lat2, lon2 })
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  // console.log(d)
  return d;
}

function toRad(Value) {
  return Value * Math.PI / 180;
}

const makeSelectGeoDealers = () => createSelector(
  selectGlobal,
  selectEntities,
  (globalState, entitiesState) => {
    const location = globalState.get('location') && globalState.get('location').toJS()
    const dealers = entitiesState.get('dealers') && entitiesState.get('dealers').toJS()
    
    return dealers && location ? Object.values(dealers).map(d => ({ label: d.name, value: d.id, d: calcCrow(parseFloat(d.latitude), parseFloat(d.longitude), parseFloat(location.latitude), parseFloat(location.longitude)) })).sort((a,b) => a.d-b.d) : []
    // globalState.get('location')
  },
);

const makeSelectGeoDealersRaw = () => createSelector(
  selectGlobal,
  selectEntities,
  (globalState, entitiesState) => {
    const location = globalState.get('location') && globalState.get('location').toJS()
    const dealers = entitiesState.get('dealers') && entitiesState.get('dealers').toJS()
    if (!dealers) return {}
    return Object.keys(dealers).reduce((acc, cur) => {
      const d = dealers[cur]
      return {
        ...acc, [cur]: { ...d, distance: calcCrow(parseFloat(d.latitude), parseFloat(d.longitude), parseFloat(location.latitude), parseFloat(location.longitude)) }
      }
    }, {})
    // globalState.get('location')
  },
);

const makeSelectCurrentClientId = () => createSelector(
  selectGlobal,
  selectEntities,
  (globalState, entitiesState) => {
    const cartId = globalState && globalState.get('cartId');
    const carts = entitiesState.get('carts') && entitiesState.get('carts').toJS();
    const clientId = carts && carts[cartId] ? carts[cartId].clientId : null;

    return clientId;
  }
);

const makeSelectCurrentDomain = () => createSelector(
  selectGlobal,
  selectEntities,
  (globalState, entitiesState) => {
    const domainId = globalState && globalState.get('currentDomainId');
    if (!domainId) return null;
    const domains = entitiesState.get('domains') && entitiesState.get('domains').toJS();
    const domain = domains[domainId];
    return domain;
  }
);

const makeSelectDomainDealers = () => createSelector(
  makeSelectCurrentDomain(),
  (currentDomain) => {
    return currentDomain ? [currentDomain.dealerId].concat(currentDomain.dealers) : []
  }
)

const makeSelectIsPayableDomain = () => createSelector(
  selectEntities,
  makeSelectDomainDealers(),
  (entities, dealerIds) => {
    const payables = entities.get('payables') && entities.get('payables').toJS() || {}
    const dealers = entities.get('dealers') && entities.get('dealers').toJS() || {}
    const payableKods = Object.keys(payables)
    const domainCodes = dealerIds.map(id => dealers[id].kod)
    return !!payableKods.find(id => domainCodes.includes(id))
  }
)


const makeSelectDomainDealersFull = () => createSelector(
  makeSelectDomainDealers(),
  selectEntities,
  (dealerIds, entitiesState) => {
    const dealers = entitiesState.get('dealers') && entitiesState.get('dealers').toJS() || [];
    return dealers && dealerIds.map(id => dealers[id]) || []
    }
)



const makeSelectCurrentUserHasDomain = () => createSelector(
  makeSelectCurrentUser(),
  selectEntities,
  (currentUser, entitiesState) => {
    const domains = entitiesState.get('domains') && entitiesState.get('domains').toJS();

    if (!!(currentUser && currentUser.domain && domains[currentUser.domain] && domains[currentUser.domain].active === true))
      return true

    const groupDomain = currentUser && currentUser.domains && currentUser.domains[0]
    if(groupDomain && groupDomain.active === true)
      return true

    return false
  }
);

const selectUserDomain = () => createSelector(
  makeSelectCurrentUser(),
  selectEntities,
  (currentUser, entitiesState) => {
    const domains = entitiesState.get('domains') && entitiesState.get('domains').toJS();

    if (!!(currentUser && currentUser.domain && domains[currentUser.domain] && domains[currentUser.domain].active === true))
      return domains[currentUser.domain]

    const groupDomain = currentUser.domains && currentUser.domains[0]
    
    if(groupDomain && groupDomain.active === true)
      return groupDomain

    return false
  }
);



const makeSelectMenu = () => createSelector(
  selectGlobal,
  (globalState) => globalState && globalState.get('menu'),
);

const makeSelectPopup = () => createSelector(
  selectGlobal,
  (globalState) => globalState.get('popup').toJS()
);

const makeSelectPopupInfo = () => createSelector(
  selectGlobal,
  (globalState) => globalState.get('popupInfo').toJS()
);

const makeSelectIsAuthChecking = () =>
  createSelector(selectGlobal, globalState => globalState.get('authChecking'));




const orderIdSelector = (state, {id}) => id

const selectOrderItems = () => createSelector(
  selectEntities,
  orderIdSelector,
  (entitiesState, id) => {
    const orders = entitiesState.get('orders') && entitiesState.get('orders').toJS() || {}
    const items = entitiesState.get('items') && entitiesState.get('items').toJS() || {}
    const products = entitiesState.get('products') && entitiesState.get('products').toJS() || {}
    const order = id && orders[id] || null

    if(!order)
      return []
    const orderItems = order.items.map((id) => ({...items[id], product: products && products[items[id].productId]}));
    // return dealers && dealers[id]
    return orderItems
  }
);

 



export {
  makeSelectFavs,
  makeSelectCurrentRegionId,
  makeSelectLocation,
  makeSelectPageData,
  makeSelectCurrentUser,
  makeSelectCurrentCartId,
  makeSelectCurrentClientId,
  makeSelectPopup,
  makeSelectPopupInfo,
  makeSelectMenu,
  makeSelectMainDomain,
  makeSelectIsDealerDomain,
  makeSelectCurrentDomain,
  makeSelectDealerOrderCount,
  makeSelectCurrentUserHasDomain,
  makeSelectDomainDealers,
  makeSelectLoading,
  makeSelectBrowserIsOk,
  makeSelectHost,
  makeSelectMessagesShow,
  makeSelectDomainLoading,
  makeSelectIsAuthChecking,
  makeSelectDomainDealersFull,
  makeSelectIsPayableDomain,
  selectCartModItems,
  selectOrderItems,
  selectCartDeliveries,
  selectUserDomain,
  selectCartItems,
  makeSelectUserLocation,
  makeSelectGeoDealers,
  makeSelectGeoDealersRaw
};
