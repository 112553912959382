/* istanbul ignore file */
export const GOOD_OFFERS = 'SkodaParts/Popup/GOOD_OFFERS';
export const PRODUCT_OFFERS = 'SkodaParts/Popup/PRODUCT_OFFERS';
export const EDIT_CLIENT = 'SkodaParts/Popup/EDIT_CLIENT';
export const EDIT_USER = 'SkodaParts/Popup/EDIT_USER';
export const EDIT_GOOD = 'SkodaParts/Popup/EDIT_GOOD';
export const EDIT_DEALER_GOOD = 'SkodaParts/Popup/EDIT_DEALER_GOOD';
export const EDIT_MODEL = 'SkodaParts/Popup/EDIT_MODEL';
export const EDIT_CATEGORY = 'SkodaParts/Popup/EDIT_CATEGORY';
export const EDIT_TAB = 'SkodaParts/Popup/EDIT_TAB';
export const EDIT_DEALER = 'SkodaParts/Popup/EDIT_DEALER';
export const EDIT_ORDER = 'SkodaParts/Popup/EDIT_ORDER';
export const EDIT_DEALER_ORDER = 'SkodaParts/Popup/EDIT_DEALER_ORDER';
export const EDIT_DOMAIN = 'SkodaParts/Popup/EDIT_DOMAIN';
export const EDIT_REGION = 'SkodaParts/Popup/EDIT_REGION';
export const EDIT_CITY = 'SkodaParts/Popup/EDIT_CITY';
export const EDIT_MANAGER = 'SkodaParts/Popup/EDIT_MANAGER';
export const EDIT_UPDATE = 'SkodaParts/Popup/EDIT_UPDATE';
export const SHOW_ORDER = 'SkodaParts/Popup/SHOW_ORDER';
export const SHOW_DEALER_ORDER = 'SkodaParts/Popup/SHOW_DEALER_ORDER';
export const SHOW_ORDER_LOGS = 'SkodaParts/Popup/SHOW_ORDER_LOGS';
export const SHOW_GOOD_LOGS = 'SkodaParts/Popup/SHOW_GOOD_LOGS';
export const SHOW_DEALER_LOGS = 'SkodaParts/Popup/SHOW_DEALER_LOGS';
export const RESTORE_PASSWORD = 'SkodaParts/Popup/RESTORE_PASSWORD';
export const CREATE_FEEDBACK = 'SkodaParts/Popup/CREATE_FEEDBACK';
export const SHOW_ORDERS = 'SkodaParts/Popup/SHOW_ORDERS';
export const SHOW_GOODS = 'SkodaParts/Popup/SHOW_GOODS';
export const SHOW_DEALERS = 'SkodaParts/Popup/SHOW_DEALERS';
export const SHOW_OFFERS = 'SkodaParts/Popup/SHOW_OFFERS';
export const NOTIFY_STOCK = 'SkodaParts/Popup/NOTIFY_STOCK';
export const EDIT_NOTIFICATION = 'SkodaParts/Popup/EDIT_NOTIFICATION';
export const EDIT_PODBOR = 'SkodaParts/Popup/EDIT_PODBOR';
export const USER_AGREEMENT = 'SkodaParts/Popup/USER_AGREEMENT';
export const AGREEMENT2 = 'SkodaParts/Popup/AGREEMENT2';
export const DEALER_ORDERS_STATS = 'SkodaParts/Popup/DEALER_ORDERS_STATS';
export const SHOW_ORDER_PAYMENT = 'SkodaParts/Popup/SHOW_ORDER_PAYMENT';
export const BONUS_POPUP = 'SkodaParts/Popup/BONUS_POPUP';
export const EDIT_IMPORTER_ORDER = 'SkodaParts/Popup/EDIT_IMPORTER_ORDER';
export const DELIVERY_FORM = 'SkodaParts/Popup/DELIVERY_FORM';
export const EDIT_RETURN = 'SkodaParts/Popup/EDIT_RETURN';
export const SET_PODBOR = 'SkodaParts/Popup/SET_PODBOR';


