import React from 'react'

import { H4, Gapper } from 'components/Ui'
import Good from 'components/Good';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectEntities } from 'models/selectors';
import { makeSelectPageData, makeSelectCurrentDomain, makeSelectFavs } from 'containers/App/selectors';
import { favToggle, setPopup } from 'containers/App/actions';

const HomeTab = ({ tab, images, setPopup, favToggle, favs, compares }) => {

  return (
    <section>
      <Gapper>
        <H4>{tab.title}</H4>
        {tab.goods.map(good => {
          return (
            <Good key={`tab-good-${good.id}`} row={good} images={images} setPopup={setPopup} favToggle={favToggle} favs={favs} compares={compares} />
          )
        })}

      </Gapper>
    </section>
  )
}



function mapDispatchToProps(dispatch) {
  return {
    getTabs: (payload) => dispatch(getTabs(payload)),
    setPopup: (id, opts) => dispatch(setPopup(id, opts)),
    favToggle: (payload) => dispatch(favToggle(payload)),
  };
}

const mapStateToProps = createStructuredSelector({
  images: makeSelectEntities('images'),
  favs: makeSelectFavs(),
  compares: makeSelectFavs(1),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(HomeTab);