/**
 *
 * App.js
 *
 */

 // "react-select": "^1.2.1",


import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import filtersSaga from 'components/Filter/saga';
import sagaUsers from 'models/users/saga';
import sagaDealers from 'models/dealers/saga';
import sagaReturns from 'models/returns/saga';
import goodsSaga from 'models/goods/saga';
import ssoSaga from 'containers/Sso/saga';
import messagesSaga from 'models/messages/saga';
import feedbacksSaga from 'models/feedbacks/saga';
import podborsSaga from 'models/podbors/saga';
import sagaOrders from 'models/orders/saga';
import Loading from 'components/Loading';
import { frontloadConnect } from 'react-frontload';
import { ThemeProvider } from 'styled-components';
import { StoreProvider, Media, theme } from '@vgr/skoda-storybook';
const finalTheme = theme.skoda(0, 1920);



import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import messages from 'config/messages';

import Popup from 'components/Popup';
import Dashboard from 'containers/Dashboard/Loadable';
import Admin from 'containers/Admin/Loadable';
import Transition from 'components/Transition';
import Sso from 'containers/Sso';
import Payment from 'containers/Payment'
import Delivery from 'containers/Delivery'

import Messages from 'components/Messages';
import PopupPriceDisclaimer from 'components/PopupPriceDisclaimer';

import ReactTooltip from 'react-tooltip';
import Routes from './Routes';
import {
  checkAuth, setPopup, setMenu, initialLoad, initialSet,
} from './actions';
import {
  makeSelectLocation, makeSelectPopup, makeSelectPopupInfo, makeSelectMenu, makeSelectMainDomain, makeSelectCurrentDomain, 
  makeSelectPageData, makeSelectBrowserIsOk, makeSelectDomainLoading,
} from './selectors';
import saga from './saga';

const DealerDomainLoader = ({ domainLoading }) => (
  <React.Fragment>
    {domainLoading
      ? (
        <div className="fp-loading">
          Загрузка..
        </div>
      )
      : (
        <div
          style={{
            paddingTop: 200, margin: '0 auto', maxWidth: 400, width: '100%',
          }}
          dangerouslySetInnerHTML={{ __html: messages.pages.domainNotActive }}
        />
      )
    }

  </React.Fragment>
);
const IeFail = () => (
  <React.Fragment>
    <div
      style={{
        paddingTop: 200, margin: '0 auto', maxWidth: 400, width: '100%',
      }}
      dangerouslySetInnerHTML={{ __html: messages.pages.oldIe }}
    />
  </React.Fragment>
);

const frontload = async (props) => {
  // props.setPageData('domainLoading', 'true');
  return new Promise((resolve, reject) => {
    props.initialLoad({ resolve, reject });
  })
}

class App extends React.PureComponent {
  componentDidMount () {
    this.props.initialSet()
    this.props.checkAuth();
  }

  UNSAFE_componentWillReceiveProps (nextProps) {

    if (nextProps.popup.length > 0) {
      document.body.classList.add('body_popup');
    } else {
      document.body.classList.remove('body_popup');
    }
  }

  render () {
    const {
      popup, popupInfo, popupClose, menu, isMainDomain, domain, pageData, browserIsOk
    } = this.props;
    const isDomainValid = isMainDomain || domain != null;
    // const ieOk = !ieVersion.version || ieVersion.version && ieVersion.version > 9; // eslint-disable-line no-mixed-operators
    const ieOk = browserIsOk;
    return (
      <React.Fragment>
        <Helmet
          titleTemplate="%s"
          defaultTitle="Оригинальные детали ŠKODA"
          meta={[
            { name: 'description', content: 'Оригинальные детали ŠKODA' },
          ]}
        />
        
        <Sso />

        <Payment />

        <Delivery />

        <Loading />

        <StoreProvider theme={finalTheme}>
          <Media.Component theme={finalTheme} />
          <ThemeProvider theme={finalTheme}>
            { isDomainValid && ieOk &&
              (
                <Switch>
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/admin" component={Admin} />

                  <Route path="/" component={Routes} />
                </Switch>
              )
            }
          </ThemeProvider>
        </StoreProvider>

        <Transition>
          { !isDomainValid &&
            <DealerDomainLoader pageData={pageData} />
          }
        </Transition>

        { !ieOk &&
          <IeFail />
        }

        <Transition>
          {menu &&
            <div className="overlay" onClick={() => { this.props.setMenu(null); }} />
          }
        </Transition>

        <>
          {!_.isEmpty(popup) &&
            <Popup popup={popup} popupInfo={popupInfo} close={popupClose} />
          }
        </>

        <ReactTooltip />

        <Messages />
        
        <PopupPriceDisclaimer />

      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  // Home: makeSelectHome(),
  popup: makeSelectPopup(),
  popupInfo: makeSelectPopupInfo(),
  menu: makeSelectMenu(),
  location: makeSelectLocation(),
  isMainDomain: makeSelectMainDomain(),
  domain: makeSelectCurrentDomain(),
  pageData: makeSelectPageData('app'),
  domainLoading: makeSelectDomainLoading(),
  browserIsOk: makeSelectBrowserIsOk(),
});

function mapDispatchToProps (dispatch) {
  return {
    checkAuth: () => dispatch(checkAuth()),
    initialLoad: (payload) => dispatch(initialLoad(payload)),
    initialSet: () => dispatch(initialSet()),
    popupClose: (e) => {
      dispatch(setPopup(null));
    },
    setPopup: (id) => dispatch(setPopup(id)),
    setMenu: (id) => dispatch(setMenu(id)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);


const withSaga = injectSaga({ key: 'global', saga });
const withUsersSaga = injectSaga({ key: 'users', saga: sagaUsers });
const withDealersSaga = injectSaga({ key: 'dealers', saga: sagaDealers });
const withFiltersSaga = injectSaga({ key: 'filters', saga: filtersSaga });
const withGoodsSaga = injectSaga({ key: 'goods', saga: goodsSaga });
const withFeedbacksSaga = injectSaga({ key: 'feedbacks', saga: feedbacksSaga });
const withReturnsSaga = injectSaga({ key: 'returns', saga: sagaReturns });
const withPodborsSaga = injectSaga({ key: 'podbors', saga: podborsSaga });

const withSsoSaga = injectSaga({ key: 'sso', saga: ssoSaga });
const withMessagesSaga = injectSaga({ key: 'messages', saga: messagesSaga });



const withOrdersSaga = injectSaga({ key: 'orders', saga: sagaOrders });


const withFrontload = frontloadConnect(frontload)

export default withRouter(compose(
  withSaga,
  withFiltersSaga,
  withUsersSaga,
  withDealersSaga,
  withGoodsSaga,
  withFeedbacksSaga,
  withSsoSaga,
  withMessagesSaga,
  withReturnsSaga,
  withOrdersSaga,
  withConnect,
  withFrontload,
  withPodborsSaga,
)(App));
