import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux';
import { getTabs } from 'containers/App/actions'
import { compose } from 'redux';
import HomeTab from './HomeTab';
import { Gapper } from 'components/Ui'

const HomeTabs = (props) => {
  const { getTabs } = props
  const [tabs, setTabs] = useState([])
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    new Promise((resolve, reject) => {
      getTabs({ resolve, reject })
    }).then((r) => {
      setTabs(r)
      setLoading(false)
    }).catch(e => {
      setLoading(false)
    })
  }, [])

  return (
    <Gapper>
      {tabs.map(tab => {
        return (
          <HomeTab tab={tab} />
        )
      })}
    </Gapper>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    getTabs: (payload) => dispatch(getTabs(payload))
  };
}

const withConnect = connect(null, mapDispatchToProps);


export default compose(
  withConnect,
)(HomeTabs);
