/**
*
* ProductCartButton
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { delay } from 'helpers';

class ProductCartButton extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props);
    this.state = {
      quantity: props.item ? props.item.quantity : 0,
      loading: false,
    };
    this.minusOne = this.minusOne.bind(this);
    this.plusOne = this.plusOne.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.customValue = this.customValue.bind(this);    
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (newProps.item && (!this.props.item || newProps.item.quantity !== this.props.item.quantity)) {
      this.setState({ quantity: newProps.item.quantity });
    }
  }

  addToCart = async (quantity) => {
    const { addItemToCart, productId, dealerId } = this.props;
    this.setState({ loading: true });
    return new Promise(async (resolve, reject) => {
      try {
        await addItemToCart(productId, dealerId, quantity);
        this.setState({ loading: false });
        resolve();
      } catch (e) {
        this.setState({ loading: false });
        reject(e);
      }
    });
  }

  customValue (e) {
    const { item } = this.props;
    const { addToCart } = this;
    const value = Number(e.target.value);
    if (!value) {
      this.setState({ quantity: this.props.item.quantity });
    } else {
      this.setState({ quantity: value });
      const that = this;
      delay(() => {
        addToCart(value - item.quantity).catch((err) => {
          that.setState({ quantity: item.quantity });
        });
      }, 1000);
    }
  }

  minusOne () {
    const { item } = this.props;
    if (item.quantity > 0) { this.addToCart(-1); }
  }

  plusOne () {
    const { limit } = this.props;
    if (limit === this.state.quantity) { return false; }
    this.addToCart(1);
    return true;
  }

  render () {
    const {
      item, limit,
    } = this.props;
    const itemInCart = item;
    return (
      <React.Fragment>
        {itemInCart &&
          (
            <React.Fragment>
              <div className={`inline-group${this.state.loading ? ' loading' : ''}`}>
                <span onClick={this.minusOne}>
                  <FontAwesomeIcon icon={faMinus} style={{ marginLeft: 0 }} />
                </span>
                <input type="text" value={this.state.quantity} onChange={this.customValue} />
                <span onClick={this.plusOne} className={limit === this.state.quantity ? 'disabled' : ''}>
                  <FontAwesomeIcon icon={faPlus} style={{ marginLeft: 0 }} />
                </span>
              </div>
              <span>
&nbsp;шт.
              </span>
            </React.Fragment>
          )
        }
        {!itemInCart &&
          (
            <Button disabled={this.state.loading} loading={this.state.loading} style={{ marginRight: 0 }} secondary small onClick={() => this.addToCart(1)}>
              <FontAwesomeIcon className="hoverable" icon={faShoppingCart} style={{ marginLeft: 0 }} />
            &nbsp;
            В корзину
            </Button>
          )
        }
      </React.Fragment>
    );
  }
}

ProductCartButton.propTypes = {
  addItemToCart: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  dealerId: PropTypes.number.isRequired,
  limit: PropTypes.number,
  item: PropTypes.object,
};

export default ProductCartButton;
